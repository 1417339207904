import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, Form, ProcessInstance } from '@sob/sob-resources';
import { ActiveUnitData, MaintainProcessPayload, UnitProcessData, UserWithEmail } from './process-action.types';

@Injectable()
export class ProcessActionsService {
  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT) private readonly env: Environment
  ) {}

  terminateProcess(processInstanceId: number, notifyResident: boolean, terminateReason: string, processInstance: ProcessInstance) {
    const url = `${this.env.baseApiUrl}/processes/${processInstanceId}/terminate?notifyResident=${notifyResident}&terminateReason=${terminateReason}`;
    return this.http.post<ProcessInstance>(url, processInstance);
  }

  maintainProcess(processInstanceId: number, maintainDuration: number, moduleIds: MaintainProcessPayload) {
    const url = `${this.env.baseApiUrl}/processes/${processInstanceId}/maintain?maintainDuration=${maintainDuration}`;
    return this.http.post<ProcessInstance>(url, moduleIds);
  }

  // Form related API-call temporarily located in this service until more form-related components are added.
  getDraftedForms(processInstanceId: number) {
    return this.http.get<Form[]>(`${this.env.baseApiUrl}/processes/${processInstanceId}/forms/drafts`);
  }

  getActiveUnits() {
    return this.http.get<ActiveUnitData[]>(`${this.env.baseApiUrl}/unit/activeunits`);
  }

  getLatestProcessVersionFromUnit(unitHsaId: string, processInstanceId: number, majorVersion: number) {
    return this.http.get<UnitProcessData>(`${this.env.baseApiUrl}/unit/${unitHsaId}/process/${processInstanceId}/${majorVersion}`);
  }

  getActiveCareprovidersForUnit() {
    return this.http.get<UserWithEmail[]>(`${this.env.baseApiUrl}/unit/careproviders`);
  }
}
