import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@sob/sob-resources';
import { ExtendedEnvironment } from 'src/environments/extended-environment.model';

@Injectable()
export class PreviewFormService {
  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT) private readonly env: ExtendedEnvironment
  ) {}

  markFormAsRead(processInstanceId: string, formId: string, readStatus: boolean = true) {
    return this.http.put<void>(`${this.env.baseApiUrl}/processes/${processInstanceId}/forms/${formId}/read`, {
      value: readStatus,
    });
  }
}
