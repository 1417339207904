import { CustomError, ErrorHandlerConfig } from '@sob/sob-resources';
import { customErrorMessages } from './error-handler';

const redirectErrorCodes = [401, 403, 500, 502, 503, 504];

export const CustomErrorHandlerConfig: ErrorHandlerConfig = {
  errorPath: 'error',
  customErrorMessages: {
    403: customErrorMessages[403],
    500: customErrorMessages[500],
  },
  shouldRedirectOnError: (err: CustomError) => {
    if (!redirectErrorCodes.includes(err.status)) {
      return false;
    }

    //status 500 and errorCode from 1000 and higher are not supposed to redirect to the big error page
    if (err.status === 500 && err.errorCode >= 1000) {
      return false;
    }

    return true;
  },
};
