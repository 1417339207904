import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Handler, ProfessionRole } from '@sob/sob-resources';
import { HandlerState, LoadHandler } from '../states/handler.state';


export const redirectGuard = async () => {

  const router = inject(Router);
  const store = inject(Store);

  if (!store.selectSnapshot(HandlerState.loaded)) {
    await store.dispatch(new LoadHandler()).toPromise();
  }

  const handler = store.selectSnapshot(HandlerState.handler)!;

  if (handler.onlyDesignerOrPublisher()) {
    window.location.replace(`${document.location.protocol}//${document.location.hostname}/designer/`);
    return false;
  }

  return router.createUrlTree([getRedirectUrl(handler)]);

}

const getRedirectUrl = (handler: Handler) => {
  if (handler.hasRole(ProfessionRole.HANDLER)) {
    return 'processes/handler';
  }
  if (handler.hasRole(ProfessionRole.RESIDENT_MANAGER)) {
    return 'processes/unit';
  }
  if (handler.hasRole(ProfessionRole.USER_MANAGER)) {
    return 'personnel';
  }
  if (handler.hasRole(ProfessionRole.UNIT_MANAGER)) {
    return 'unit-administration';
  }
  if (handler.hasRole(ProfessionRole.NATIONAL_MANAGER)) {
    return 'national-administration';
  }
  if (handler.hasRole(ProfessionRole.CAREPROVIDER_MANAGER)) {
    return 'care-provider-administration';
  }
  if (handler.hasRole(ProfessionRole.DATA_ADMIN)) {
    return 'processes/done';
  }
  if (handler.hasRole(ProfessionRole.ADMIN)) {
    return 'services-dashboard';
  }
  return 'error';
}
