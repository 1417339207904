import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { HandlerProcessInstanceService, Handover, HandoverService, ProcessInstance } from '@sob/sob-resources';
import { HandoverData, TerminateReason } from '../manage/process-actions/process-action.types';
import { ProcessActionsService } from '../manage/process-actions/process-actions.service';
import { ClearModulesState } from './modules.state';

enum ProcessInstanceHandoverCancelled {
  CANCELLEDBYOWNER = 'CANCELLED_BY_OWNER',
}

export class InitProcessDetails {
  static readonly type = '[ProcessDetails] Init';
  constructor(public processId: string) {}
}

export class LoadProcessDetails {
  static readonly type = '[ProcessDetails] Load';
}

export class ResetProcessDetails {
  static readonly type = '[ProcessDetails] Reset';
}

export class TerminateProcess {
  static readonly type = '[ProcessDetails] TerminateProcess';
  constructor(
    public notifyResident: boolean,
    public terminateReason: TerminateReason
  ) {}
}

export class MaintainProcess {
  static readonly type = '[ProcessDetails] MaintainProcess';
  constructor(
    public maintainDuration: number,
    public selectedModulesById: number[]
  ) {}
}

export class HandoverProcess {
  static readonly type = '[ProcessDetails] HandoverProcess';
  constructor(public unitHsaId: string) {}
}

export class CancelHandover {
  static readonly type = '[ProcessDetails] CancelHandover';
}

export interface ProcessDetailsStateModel {
  loaded: boolean;
  processInstance: ProcessInstance;
  handoverData: null | HandoverData;
}

const DEFAULT_STATE: ProcessDetailsStateModel = {
  loaded: false,
  processInstance: {} as ProcessInstance,
  handoverData: null,
};

@Injectable()
@State<ProcessDetailsStateModel>({
  name: 'processDetails',
  defaults: DEFAULT_STATE,
})
export class ProcessDetailsState {
  constructor(
    private handlerProcessInstanceService: HandlerProcessInstanceService,
    private processActionsService: ProcessActionsService,
    private handoverService: HandoverService
  ) {}

  @Selector()
  static loaded(state: ProcessDetailsStateModel) {
    return state.loaded;
  }

  @Selector()
  static processInstance(state: ProcessDetailsStateModel) {
    return new ProcessInstance().deserialize(state.processInstance);
  }

  @Selector()
  static processInstanceId(state: ProcessDetailsStateModel) {
    return state.processInstance.id;
  }

  @Selector()
  static currentHandoverData(state: ProcessDetailsStateModel) {
    return state.handoverData;
  }

  @Selector()
  static showContacts(state: ProcessDetailsStateModel) {
    return state.processInstance.showContacts;
  }

  @Selector()
  static showPlanning(state: ProcessDetailsStateModel) {
    return state.processInstance.showPlanning;
  }

  @Selector()
  static showMeasurementBatteries(state: ProcessDetailsStateModel) {
    return (state.processInstance as unknown as { showMeasurementBatteries: boolean }).showMeasurementBatteries;
  }

  @Selector()
  static showActivities(state: ProcessDetailsStateModel) {
    return state.processInstance.showActivities;
  }

  @Selector()
  static showLinkedProcesses(state: ProcessDetailsStateModel) {
    return !state.processInstance.pendingHandover;
  }

  @Action(InitProcessDetails)
  async initProcessDetails(context: StateContext<ProcessDetailsStateModel>, { processId }: InitProcessDetails) {
    context.patchState({ loaded: false });

    const processInstance = await this.handlerProcessInstanceService.readOne([processId]).toPromise();
    const handoverData = processInstance?.pendingHandover
      ? await this.handoverService.readOne([processInstance.id.toString()]).toPromise()
      : null;

    context.setState({
      processInstance,
      loaded: true,
      handoverData: handoverData as HandoverData | null,
    });
  }

  @Action(LoadProcessDetails)
  async loadProcessDetails(context: StateContext<ProcessDetailsStateModel>) {
    const { loaded, processInstance } = context.getState();
    if (!loaded) return;

    const updatedProcessInstance = await this.handlerProcessInstanceService.readOne([processInstance.id.toString()]).toPromise();

    context.patchState({ processInstance: updatedProcessInstance });
  }

  @Action(ResetProcessDetails)
  resetProcessDetails(context: StateContext<ProcessDetailsStateModel>) {
    context.setState(DEFAULT_STATE);
    context.dispatch(new ClearModulesState());
    Object.keys(sessionStorage)
      .filter(x => /details\-/.test(x))
      .forEach(x => sessionStorage.removeItem(x));
  }

  @Action(TerminateProcess)
  async terminateProcess(context: StateContext<ProcessDetailsStateModel>, { notifyResident, terminateReason }: TerminateProcess) {
    const { processInstance } = context.getState();

    await this.processActionsService
      .terminateProcess(processInstance.id, notifyResident, terminateReason.toString(), processInstance)
      .toPromise();
    context.dispatch(new InitProcessDetails(processInstance.id.toString()));
  }

  @Action(MaintainProcess)
  async maintainProcess(context: StateContext<ProcessDetailsStateModel>, { maintainDuration, selectedModulesById }: MaintainProcess) {
    const { processInstance } = context.getState();

    await this.processActionsService.maintainProcess(processInstance.id, maintainDuration, { ids: selectedModulesById }).toPromise();
    context.dispatch(new InitProcessDetails(processInstance.id.toString()));
  }

  @Action(HandoverProcess)
  async initiateHandover(context: StateContext<ProcessDetailsStateModel>, { unitHsaId }: HandoverProcess) {
    const { processInstance } = context.getState();

    await this.handoverService.createOne([processInstance.id.toString(), unitHsaId]).toPromise();
    context.dispatch(new InitProcessDetails(processInstance.id.toString()));
  }

  @Action(CancelHandover)
  async cancelHandover(context: StateContext<ProcessDetailsStateModel>) {
    const { processInstance, handoverData } = context.getState();

    await this.handoverService
      .update([processInstance.id.toString()], {
        ...handoverData,
        handoverStatus: ProcessInstanceHandoverCancelled.CANCELLEDBYOWNER,
      } as unknown as Handover)
      .toPromise();
    context.dispatch(new InitProcessDetails(processInstance.id.toString()));
  }
}
