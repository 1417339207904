import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IDIconModule, IDTooltipModule } from '@inera/ids-angular';
import { ButtonModule } from '@sob/inera-component-library';
import { TableSortingButtonComponent } from './table-sorting-button.component';

@NgModule({
  declarations: [TableSortingButtonComponent],
  imports: [CommonModule, ButtonModule, IDIconModule, IDTooltipModule],
  exports: [TableSortingButtonComponent],
})
export class TableSortingButtonModule {}
