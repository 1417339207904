import { Pipe, PipeTransform } from '@angular/core';
import { ProviderUnit } from '../models/provider-unit';

@Pipe({
  name: 'unitTypeLabel',
})
export class UnitTypeLabelPipe implements PipeTransform {
  private labels: { [key in ProviderUnit['unitType']]: string } = {
    CAREPROVIDER: 'Vårdgivare',
    CAREUNIT: 'Vårdenhet',
    UNIT: 'Kopplad enhet',
  };

  transform(unitType: ProviderUnit['unitType']) {
    return this.labels[unitType];
  }
}
