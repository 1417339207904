import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IDButtonModule, IDHeaderModule, IDIconModule, IDLinkModule } from '@inera/ids-angular';
import { SwitchCareProviderModule } from '../switch-care-provider/switch-care-provider.module';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, IDHeaderModule, IDLinkModule, IDButtonModule, IDIconModule, RouterModule, SwitchCareProviderModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
