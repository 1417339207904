<inera-modal-content
  id="manage-announcement-modal"
  modalAccessibilityLabelBy="manage-announcement-modal"
  [hasCloseButton]="false"
  [checkNrOfButtonsToShowCloseButton]="false"
  (didClickCloseButton)="[close(), reset()]"
  [attr.aria-label]="editMode ? 'Redigera meddelande' : 'Lägg till meddelande'">
  <div modal-body-content>
    <h1 class="h1 modal-title">{{ editMode ? 'Redigera meddelande' : 'Lägg till meddelande' }}</h1>
    <p *ngIf="!editMode" class="ids-preamble" [innerHtml]="informationText"></p>
    <div class="row mb-md-4">
      <div class="col-12" aria-hidden="true"><span class="fw-bold text-black">* </span> = Obligatoriskt</div>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <inera-select
        *ngIf="!showDirectionOptions"
        class="d-flex flex-row col-4 ps-0"
        labelText="Mottagare"
        [selectOptions]="directionOptions"
        inputIdentifier="direction">
      </inera-select>

      <div class="d-flex align-items-start">
        <div class="me-2 mt-1">
          <inera-textfield
            inputIdentifier="startDate"
            labelText="Startdatum"
            [minDate]="minDate"
            type="date"
            [isRequired]="true"
            aria-label="Välj ett datum med format ÅÅÅÅ bindestreck MM bindestreck DD">
          </inera-textfield>
        </div>

        <id-time>
          <label for="start-time">Starttid<span class="fw-bold text-black" aria-hidden="true"> *</span></label>
          <input id="start-time" type="time" formControlName="startTime" required />
          <id-error-message *ngIf="startTimeHasError">Obligatoriskt. Vänligen ange en tidpunkt enligt: HH:MM.</id-error-message>
        </id-time>

        <span class="align-self-start mt-12 mx-4">-</span>

        <div class="me-2 mt-1">
          <inera-textfield
            inputIdentifier="endDate"
            labelText="Slutdatum"
            [minDate]="minDate"
            type="date"
            [isRequired]="true"
            aria-label="Välj ett datum med format ÅÅÅÅ bindestreck MM bindestreck DD">
          </inera-textfield>
        </div>

        <id-time>
          <label for="end-time">Sluttid<span class="fw-bold text-black" aria-hidden="true"> *</span></label>
          <input id="end-time" type="time" formControlName="endTime" required />
          <id-error-message *ngIf="endTimeHasError">Obligatoriskt. Vänligen ange en tidpunkt enligt: HH:MM.</id-error-message>
        </id-time>
      </div>

      <div *ngIf="hasError" class="mt-n4 alert bg-white shadow-sm rounded border-danger text-danger d-print-none">
        Ogiltigt datumintervall. Startdatum måste vara ett tidigare datum än upphördatum.
      </div>

      <inera-textarea
        inputIdentifier="message"
        labelText="Meddelande"
        [rows]="4"
        [cols]="60"
        [isRequired]="true"
        aria-label="Ett meddelande måste anges"
        customRequiredText="Obligatoriskt. Skriv minst ett tecken för att lägga till meddelande."
        [useCustomRequiredTextOnly]="true">
      </inera-textarea>

      <div class="d-flex justify-content-center mt-5">
        <inera-button
          buttonTitle="Avbryt"
          textClass="text-uppercase"
          colorType="outline-primary"
          sizeClass="md"
          marginClass="me-md-6 mb-4 mb-md-0"
          [isPillShaped]="true"
          [responsive]="true"
          (buttonMethod)="close()">
        </inera-button>
        <inera-submit-button buttonType="submit" [buttonLabel]="editMode ? 'SPARA MEDDELANDE' : 'LÄGG TILL MEDDELANDE'">
        </inera-submit-button>
      </div>
    </form>
  </div>
</inera-modal-content>
