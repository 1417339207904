import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, SelectorOptions, State, StateContext } from '@ngxs/store';
import { DownloadService } from 'src/app/services/download.service';
import { ExportService } from './../../pages/export/export-service';
import { ExportRequestQueryBody, StoredExportStatus } from './../../pages/export/export.types';
import { pollingStatuses } from './export-download.utils';

export class GenerateExport {
  static readonly type = '[Export download] Generate export';
  constructor(
    public body: ExportRequestQueryBody,
    public templateName: string,
    public processCount: number
  ) {}
}

export class ResetExportDownloads {
  static readonly type = '[Export download] reset';
}

export class QueryExportStatus {
  static readonly type = '[Export download] Query export status';
  constructor(public exportId: string) {}
}

export class DownloadExport {
  static readonly type = '[Export download] Download export';
  constructor(public exportId: string) {}
}

export class ShowLogoutModal {
  static readonly type = '[Export download] Show logout modal';
}

export class HideLogoutModal {
  static readonly type = '[Export download] Hide logout modal';
}

export interface ExportDownloadStateModel {
  exports: StoredExportStatus[];
  logoutModalVisible: boolean;
}

const DEFAULT_STATE: ExportDownloadStateModel = {
  exports: [],
  logoutModalVisible: false,
};

@SelectorOptions({ injectContainerState: false })
@Injectable()
@State<ExportDownloadStateModel>({
  name: 'exportDownload',
  defaults: DEFAULT_STATE,
})
export class ExportDownloadState {
  constructor(
    private exportService: ExportService,
    private downloadService: DownloadService
  ) {}

  @Selector()
  static exports(state: ExportDownloadStateModel) {
    return state.exports;
  }

  static export(exportId: string) {
    return createSelector([ExportDownloadState.exports], (exports: ExportDownloadStateModel['exports']) => {
      return exports.find(exportItem => exportItem.id === exportId);
    });
  }

  @Selector([ExportDownloadState.exports])
  static ongoingExportCount(exports: ExportDownloadStateModel['exports']) {
    return exports.filter(exportItem => pollingStatuses.includes(exportItem.progress)).length;
  }

  @Selector([ExportDownloadState.exports])
  static nonDownloadedExportCount(exports: StoredExportStatus[]) {
    return exports.filter(exportItem => exportItem.progress !== 'DOWNLOADED').length;
  }

  @Selector([ExportDownloadState.exports])
  static hasExports(exports: ExportDownloadStateModel['exports']) {
    return !!exports.length;
  }

  @Selector()
  static logoutModalVisible(state: ExportDownloadStateModel) {
    return state.logoutModalVisible;
  }

  @Action(GenerateExport)
  async generateExport(context: StateContext<ExportDownloadStateModel>, { body, templateName, processCount }: GenerateExport) {
    const exportResponse = await this.exportService.generateExport(body).toPromise();
    context.patchState({ exports: [...context.getState().exports, { ...exportResponse, templateName, processCount }] });
  }

  @Action(ResetExportDownloads)
  resetExportDownloads(context: StateContext<ExportDownloadStateModel>) {
    context.setState(DEFAULT_STATE);
  }

  @Action(DownloadExport)
  async downloadExport(context: StateContext<ExportDownloadStateModel>, { exportId }: DownloadExport) {
    const response = await this.exportService.downloadExport(exportId).toPromise();

    const contentDisposition = response.headers.get('content-disposition')!;
    const splitCD = contentDisposition.split(' ');
    const fileName = splitCD[splitCD.length - 1];

    this.downloadService.downloadFile(response.body!, fileName);

    context.patchState({
      exports: context
        .getState()
        .exports.map(exportItem => (exportItem.id === exportId ? { ...exportItem, progress: 'DOWNLOADED' } : exportItem)),
    });
  }

  @Action(QueryExportStatus)
  async queryExportStatus(context: StateContext<ExportDownloadStateModel>, { exportId }: QueryExportStatus) {
    const exportStatus = await this.exportService.getExportStatus(exportId).toPromise();

    context.patchState({
      exports: context.getState().exports.map(exportItem => (exportItem.id === exportId ? { ...exportItem, ...exportStatus } : exportItem)),
    });
  }

  @Action(ShowLogoutModal)
  showLogoutModal(context: StateContext<ExportDownloadStateModel>) {
    context.patchState({ logoutModalVisible: true });
  }

  @Action(HideLogoutModal)
  hideLogoutModal(context: StateContext<ExportDownloadStateModel>) {
    context.patchState({ logoutModalVisible: false });
  }
}
