<inera-tooltip-button
  #exportTooltip
  [autoClose]="'outside'"
  trigger="manual"
  buttonType="button"
  screenReaderText="Visa information om urval"
  iconClass="tooltip-icon"
  placement="top-right auto">
  <inera-tooltip-content [tooltipReference]="exportTooltip" class="text-start" [showCloseButton]="true" [isResponsive]="true">
    <h4>Exporteras</h4>
    <h5 class="mt-2">Urval:</h5>
    <span>
      {{ exportStatus.templateName }} <br />
      {{ getExportRequestStatusLabel(exportStatus.request.status) }} <br />
      <span [innerHTML]="ExportRequestTypeText"></span> <br />
      <ng-container *ngIf="exportStatus.request.type === 'CATEGORY'">
        <ng-container *ngFor="let category of exportStatus.request.categories; trackBy: stringTrackBy">
          {{ category }} <br />
        </ng-container>
      </ng-container>
    </span>
    <span *ngIf="startPeriod">
      <strong>Startdatum:</strong> <br />
      {{ startPeriod }}
    </span>
    <span *ngIf="endPeriod">
      <strong>Avslutdatum:</strong> <br />
      {{ endPeriod }}
    </span>
  </inera-tooltip-content>
</inera-tooltip-button>
