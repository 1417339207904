import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IDIconModule, IDSelectModule } from '@inera/ids-angular';
import { PaginationComponent } from './pagination.component';

@NgModule({
  declarations: [PaginationComponent],
  imports: [CommonModule, IDIconModule, IDSelectModule, ReactiveFormsModule],
  exports: [PaginationComponent],
})
export class PaginationModule {}
