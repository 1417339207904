import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchCurrentViewComponent } from './switch-current-view.component';
import { IDSelectModule } from '@inera/ids-angular';

@NgModule({
  declarations: [SwitchCurrentViewComponent],
  imports: [CommonModule, IDSelectModule],
  exports: [SwitchCurrentViewComponent],
})
export class SwitchCurrentViewModule {}
