import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, ProcessTemplate } from '@sob/sob-resources';
import {
  Category,
  ExportRequestFilter,
  ExportRequestFilterBody,
  ExportRequestQueryBody,
  ExportRequestQueryResponse,
  ExportRequestStatus,
  ExportStatus,
} from './export.types';

@Injectable()
export class ExportService {
  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT) private readonly env: Environment
  ) {}

  getProcesses(status: ExportRequestStatus) {
    return this.http.get<ProcessTemplate[]>(`${this.env.baseApiUrl}/unit/processes/export/${status.toLowerCase()}`);
  }

  queryInformation(body: ExportRequestQueryBody) {
    return this.http.post<ExportRequestQueryResponse>(`${this.env.baseApiUrl}/unit/processes/export/query`, body);
  }

  getProcessCategories(templateId: string, status: ExportRequestStatus) {
    return this.http.post<Category[]>(`${this.env.baseApiUrl}/unit/processes/export/categories`, { status, templateId, type: 'ALL' });
  }

  getFilters() {
    return this.http.get<ExportRequestFilter[]>(`${this.env.baseApiUrl}/unit/processes/export/filter`);
  }

  createFilter(body: ExportRequestFilterBody) {
    return this.http.post<ExportRequestFilter>(`${this.env.baseApiUrl}/unit/processes/export/filter`, body);
  }

  deleteFilter(filterId: number) {
    return this.http.delete(`${this.env.baseApiUrl}/unit/processes/export/filter/${filterId}`);
  }

  generateExport(body: ExportRequestQueryBody) {
    return this.http.post<ExportStatus>(`${this.env.baseApiUrl}/unit/processes/export`, body);
  }

  getExportStatus(exportId: string) {
    return this.http.get<ExportStatus>(`${this.env.baseApiUrl}/unit/processes/export/${exportId}`);
  }

  downloadExport(exportId: string) {
    return this.http.get(`${this.env.baseApiUrl}/unit/processes/export/${exportId}/file`, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
