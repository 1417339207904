import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  IDHeaderAvatar,
  IDHeaderItem,
  IDHeaderNavItem,
  IDLinkFunction,
  IDLinkRoute,
  IDMobileMenuItem,
} from '@inera/ids-angular';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GlobalAnnouncementStore, Handler, ProfessionHandlerConstantPropertyService, ProfessionRole } from '@sob/sob-resources';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { DestroyService } from 'src/app/services/destroy.service';
import { SystemSettings } from 'src/app/states/system.state';
import { defaultLinkActiveOptions } from 'src/app/utils/route.utils';
import { getNavItems, HeaderNavItem, navItemOptions } from './header.utils';

@Component({
  selector: 'handler-header',
  templateUrl: './header.component.html',
  styles: [
    `
      /* Need this wrapper until IDS have removed the padding on header. */
      .wrapper {
        position: relative !important;
        background-color: white !important;
        z-index: 2;
      }

      .main-header {
        @media screen and (max-width: 1025px) {
          display: none;
        }
      }

      .mobile-header {
        @media screen and (min-width: 1025px) {
          display: none;
        }
      }
    `,
  ],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  appName: string;
  avatar: IDHeaderAvatar;
  switchCareProviderBtn: IDHeaderItem[];
  desktopNavItems: IDHeaderNavItem[] = [];
  mobileMenuItems: IDMobileMenuItem[] = [];

  @Input() handler: Handler;
  @Input() systemSettings: SystemSettings;

  @ViewChild('switchCareProviderModal') modalRef: NgbModalRef;

  constructor(
    private constantPropertyService: ProfessionHandlerConstantPropertyService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private router: Router,
    private destroy$: DestroyService,
    private announcementStore: GlobalAnnouncementStore
  ) {}

  ngOnInit() {
    const properties = this.constantPropertyService.getConstantProperties();
    this.appName = this.systemSettings.showDesignerToolGui || this.handler.designerTool ? properties.designer_title : properties.sob_title;

    const username = this.handler.protectedPerson ? 'SKYDDAD IDENTITET' : `${this.handler.firstName} ${this.handler.lastName}`;
    this.avatar = new IDHeaderAvatar({
      username,
      linkMobile: new IDLinkRoute({ label: 'Logga ut', route: '/sob-handler-web/logout' }),
    });

    this.switchCareProviderBtn = [
      new IDHeaderItem({
        iconName: 'swap',
        link: new IDLinkFunction({
          label: 'Växla vårdgivare',
          action: () => {
            this.openModal();
          },
        }),
      }),
    ];

    if (this.handler.isSignedIn) {
      const navItems = getNavItems(this.handler, this.systemSettings);
      this.setupNavigation(navItems);
      this.setMobileNavItems(navItems);
    }
  }

  get isCareProviderManager(): boolean {
    return this.handler.roles?.some(role => role === ProfessionRole.CAREPROVIDER_MANAGER);
  }

  openModal() {
    this.modalService.open(this.modalRef, {
      size: 's',
      centered: true,
    });
  }

  private setupNavigation(navItems: HeaderNavItem[]) {
    const navigationEnd$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    navigationEnd$.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateNavItems(navItems));

    navigationEnd$
      .pipe(
        takeUntil(this.destroy$),
        map(event => {
          return navItemOptions.find(item => (event as NavigationEnd).url.includes(item.route));
        }),
        distinctUntilChanged((a, b) => a?.route === b?.route),
        filter(item => !!item)
      )
      .subscribe(() => this.announcementStore.getAnnouncements());
  }

  private updateNavItems(navItems: HeaderNavItem[]) {
    this.desktopNavItems = navItems.map(
      item =>
        new IDHeaderNavItem({
          link: new IDLinkRoute({
            label: item.label,
            route: item.route,
          }),
          active: this.router.isActive(item.route, defaultLinkActiveOptions),
        })
    );
    this.changeDetectorRef.detectChanges();
  }

  private setMobileNavItems(navItems: HeaderNavItem[]) {
    this.mobileMenuItems = navItems.map(
      item =>
        new IDMobileMenuItem({
          link: new IDLinkRoute({
            label: item.label,
            route: item.route,
          }),
          active: this.router.isActive(item.route, defaultLinkActiveOptions),
        })
    );
    this.changeDetectorRef.detectChanges();
  }
}
