import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IDAlertModule, IDButtonModule, IDErrorMessageModule, IDIconModule, IDTimeModule, IDTooltipModule } from '@inera/ids-angular';
import {
  AccordionModule,
  ButtonModule,
  LoaderModule,
  ModalContentModule,
  SelectModule,
  SubmitButtonModule,
  TextareaModule,
  TextfieldModule,
} from '@sob/inera-component-library';
import { HandlerPipesModule } from 'src/app/pipes/pipes.module';
import { AnnouncementsComponent } from './announcements.component';
import { AnnouncementsListComponent } from './list/announcements-list.component';
import { AnnouncementsManageModalComponent } from './manage-modal/announcements-manage-modal.component';
import { AnnouncementsRemoveModalComponent } from './remove-modal/announcements-remove-modal.component';

@NgModule({
  declarations: [AnnouncementsComponent, AnnouncementsListComponent, AnnouncementsManageModalComponent, AnnouncementsRemoveModalComponent],
  imports: [
    AccordionModule,
    ButtonModule,
    CommonModule,
    HandlerPipesModule,
    IDAlertModule,
    IDButtonModule,
    IDErrorMessageModule,
    IDIconModule,
    IDTimeModule,
    IDTooltipModule,
    ModalContentModule,
    LoaderModule,
    ReactiveFormsModule,
    SelectModule,
    SubmitButtonModule,
    TextareaModule,
    TextfieldModule,
  ],
  exports: [AnnouncementsComponent],
})
export class AnnouncementsModule {}
