import { ActivatedRouteSnapshot, IsActiveMatchOptions, RouterStateSnapshot } from '@angular/router';

export const defaultLinkActiveOptions: IsActiveMatchOptions = {
  paths: 'subset',
  queryParams: 'subset',
  fragment: 'ignored',
  matrixParams: 'ignored',
};

const getRouteDataRecursive = (route: ActivatedRouteSnapshot): any[] =>
  route.children.reduce((acc, cur) => [...acc, cur.data, ...getRouteDataRecursive(cur)], [] as any);

export const getRouteData = (snapshot: RouterStateSnapshot): any =>
  getRouteDataRecursive(snapshot.root).reduce((acc, cur) => ({ ...acc, ...cur }));
