<id-footer servicename="Stöd och behandling" [mobilemenuitems]="mobileFooterLinks" type="1177-pro">
  <p>
    Med Stöd och behandling kan regionerna och privata vårdgivare med offentlig finansiering erbjuda stöd, behandlingar och utbildningar
    till invånarna på ett säkert sätt via 1177 e-tjänster.
  </p>

  <!-- main footer links (for desktop), placed in slots, see: https://ids-angular.netlify.app/?path=/docs/component-footer--slots -->
  <ng-container *ngFor="let footerLink of desktopFooterLinks; let i = index; let even = even; trackBy: trackByFn">
    <id-link
      title="Öppnas i ny flik"
      [prependicon]="chevronIcon"
      [external]="footerLink.target ? true : false"
      [appendicon]="footerLink.target ? externalIcon : null"
      [attr.slot]="even ? 'link-col-1' : 'link-col-2'">
      <a [attr.href]="footerLink.url" [attr.target]="footerLink.target" class="text-danger">{{ footerLink.label }} </a>
    </id-link>
  </ng-container>

  <!-- sub footer, todo, these links needs to be updated in later tasks -->
  <ng-container *ngFor="let bottomFooterLink of bottomFooterLinks; let i = index; let even = even; trackBy: trackByFn">
    <id-link
      slot="sub-footer-right"
      title="Öppnas i ny flik"
      [external]="bottomFooterLink.target ? true : false"
      [appendicon]="bottomFooterLink.target ? externalIconSubFooter : null">
      <a [attr.href]="bottomFooterLink.url" [attr.target]="bottomFooterLink.target" class="text-white">{{ bottomFooterLink.label }}</a>
    </id-link>
  </ng-container>

  <p slot="sub-footer-left" class="mb-0">
    Stöd och behandling drivs av
    <id-link slot="sub-footer-right" title="Öppnas i ny flik" [external]="true" [appendicon]="externalIconSubFooter">
      <a href="https://inera.se" target="_blank" class="text-white">Inera.se</a>
    </id-link>
    på uppdrag av Sveriges regioner.
  </p>
</id-footer>
