import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderModule } from '@sob/inera-component-library';
import { CommentsModule, ConversationsModule } from '@sob/sob-message';
import { ProcessCommentsComponent } from './comments/process-comments.component';
import { ProcessMessagesComponent } from './messages/process-messages.component';

@NgModule({
  declarations: [ProcessMessagesComponent, ProcessCommentsComponent],
  imports: [ConversationsModule, CommentsModule, CommonModule, LoaderModule],
  exports: [ProcessCommentsComponent, ProcessMessagesComponent],
})
export class ProcessConversationsModule {}
