import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Announcement, AnnouncementData } from 'src/app/models/announcement';
import { trackByFn } from 'src/app/utils/track-by.utils';

@Component({
  selector: 'handler-announcements-list',
  templateUrl: './announcements-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementsListComponent {
  announcementId: string;
  announcement: Announcement;

  @Input() announcements: Announcement[];
  @Input() showDirectionOptions: boolean;

  @Output() editEvent = new EventEmitter<{ announcementId: string; announcementData: AnnouncementData }>();
  @Output() removeEvent = new EventEmitter<string>();

  @ViewChild('removeAnnouncementModal') removeAnnouncementRef: NgbModalRef;
  @ViewChild('editAnnouncementModal') editAnnouncementRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  getTitle(announcement: Announcement): string {
    const direction = announcement.direction === 'PERSONELL' ? 'Personal' : 'Invånare';
    const message = announcement.text.length > 70 ? `${announcement.text.slice(0, 70)}...` : announcement.text;
    const formattedStartDate = this.formatDate(announcement.startDate);
    const formattedEndDate = this.formatDate(announcement.endDate);

    return `<strong>${direction}</strong> ${message} <br/> <small>Startas: ${formattedStartDate} - Upphör: ${formattedEndDate}</small>`;
  }

  formatDate = (dateString: string) => moment(dateString).format('YYYY-MM-DD HH:mm');

  openEditAnnouncementModal(announcement: Announcement, event: Event) {
    event.stopPropagation();
    this.announcement = announcement;
    this.modalService.open(this.editAnnouncementRef, {
      size: 'md',
      centered: true,
    });
  }

  openRemoveAnnouncementModal(announcementId: number, event: Event) {
    event.stopPropagation();
    this.announcementId = announcementId.toString();
    this.modalService.open(this.removeAnnouncementRef, {
      size: 'sm',
      centered: true,
    });
  }

  editAnnouncement(announcementData: AnnouncementData) {
    this.editEvent.emit({ announcementId: this.announcement.id.toString(), announcementData });
    this.closeModal();
  }

  removeAnnouncement() {
    this.removeEvent.emit(this.announcementId);
    this.closeModal();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  trackAnnouncementByFn = trackByFn<Announcement>(announcement => announcement.id);
}
