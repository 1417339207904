import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Conversation, UserRole } from '@sob/sob-resources';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConversationsState, LoadConversations, OpenConversationModal, SetConversations } from 'src/app/states/conversations.state';
import { ProcessDetailsState } from '../../../states/process-details.state';

@Component({
  selector: 'handler-process-messages',
  templateUrl: './process-messages.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessMessagesComponent implements OnInit {
  processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);
  userRole = UserRole.HANDLER;
  sessionStorageKey: string = 'conversation-panel-ids';
  activeIds: number[] = [];
  mutableConversations$ = new BehaviorSubject<Conversation[]>([]);

  @Select(ConversationsState.loaded) loaded$: Observable<boolean>;
  @Select(ConversationsState.modalConversationId) modalConversationId$: Observable<number>;

  constructor(private store: Store) {}

  get isResidentAccountInactivated() {
    return this.processInstance.resident.profileInactivated;
  }

  get isAgentAccountInactivated() {
    return this.processInstance.resident.agentProfilesInactivated;
  }

  ngOnInit(): void {
    this.activeIds = this.getInitialIdsFromSessionStorage();
    this.loadConversations();
  }

  async loadConversations() {
    await this.store.dispatch(new LoadConversations(this.processInstance.id)).toPromise();
    const conversations = this.deserialize(this.store.selectSnapshot(ConversationsState.conversations));
    this.mutableConversations$.next(conversations);
  }

  getInitialIdsFromSessionStorage() {
    const storedIds = sessionStorage.getItem(this.sessionStorageKey);
    return storedIds ? JSON.parse(storedIds) : [];
  }

  openInModal(conversationId: number) {
    this.store.dispatch(new OpenConversationModal(this.processInstance.id, conversationId));
  }

  setActiveIds(activeIds: Array<number>) {
    this.activeIds = activeIds;
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(this.activeIds));
  }

  updateConversations(conversations: Conversation[]): void {
    this.store.dispatch(new SetConversations(this.deserialize(conversations)));
  }

  private deserialize(conversations: Conversation[]) {
    return conversations.map(x => new Conversation().deserialize(x));
  }
}
