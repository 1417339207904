import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IDAlertModule, IDBadgeModule, IDIconModule, IDProgressbarModule } from '@inera/ids-angular';
import { NgxsModule } from '@ngxs/store';
import { AccordionModule, ModalContentModule, TooltipButtonModule, TooltipContentModule } from '@sob/inera-component-library';
import { ExportService } from 'src/app/pages/export/export-service';
import { HandlerPipesModule } from 'src/app/pipes/pipes.module';
import { DownloadService } from 'src/app/services/download.service';
import { ExportDownloadTooltipComponent } from './export-download-tooltip/export-download-tooltip.component';
import { ExportDownloadComponent } from './export-download.component';
import { ExportDownloadState } from './export-download.state';

@NgModule({
  declarations: [ExportDownloadComponent, ExportDownloadTooltipComponent],
  imports: [
    CommonModule,
    IDIconModule,
    IDProgressbarModule,
    IDAlertModule,
    IDBadgeModule,
    AccordionModule,
    TooltipButtonModule,
    TooltipContentModule,
    ModalContentModule,
    HandlerPipesModule,
    NgxsModule.forFeature([ExportDownloadState]),
  ],
  exports: [ExportDownloadComponent],
  providers: [ExportService, DownloadService],
})
export class ExportDownloadModule {}
