import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {
  transform(arrayLength: number, baseWord: string, ending: string): string {
    const baseText = `${arrayLength} ${baseWord}`;
    if (ending.startsWith('/')) {
      const pluralForm = ending.slice(1);
      return arrayLength === 1 ? `${arrayLength} ${baseWord}` : `${arrayLength} ${pluralForm}`;
    }
    if (baseWord.indexOf(' ') >= 0 && ending.indexOf(' ') >= 0) {
      const word = baseWord.split(' ');
      const end = ending.split(' ');
      return arrayLength === 1 ? baseText : `${arrayLength} ${word[0]}${end[0]} ${word[1]}${end[1]}`;
    }
    return arrayLength === 1 ? baseText : `${baseText}${ending}`;
  }
}
