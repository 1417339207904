import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgentTypeTextPipe } from './agent-type-text.pipe';
import { ConvertNewlinePipe } from './convert-new-line.pipe';
import { GroupByNestedKeyPipe } from './group-by-nested-key.pipe';
import { InstanceStatusLabelPipe } from './instance-status-label.pipe';
import { PluralizePipe } from './pluralize.pipe';
import { ProviderUnitValidityTextPipe } from './provider-unit-validity-text.pipe';
import { ShowAgeTextPipe } from './show-age-text.pipe';
import { SortByPipe } from './sort-by.pipe';
import { TransformUrlToLinkPipe } from './transform-url-to-link.pipe';
import { TruncateStringPipe } from './truncate-string.pipe';
import { UnitTypeLabelPipe } from './unit-type-label.pipe';

@NgModule({
  declarations: [
    InstanceStatusLabelPipe,
    SortByPipe,
    AgentTypeTextPipe,
    TransformUrlToLinkPipe,
    ProviderUnitValidityTextPipe,
    UnitTypeLabelPipe,
    ShowAgeTextPipe,
    PluralizePipe,
    TruncateStringPipe,
    GroupByNestedKeyPipe,
    ConvertNewlinePipe,
  ],
  imports: [CommonModule],
  exports: [
    InstanceStatusLabelPipe,
    SortByPipe,
    AgentTypeTextPipe,
    TransformUrlToLinkPipe,
    ProviderUnitValidityTextPipe,
    UnitTypeLabelPipe,
    ShowAgeTextPipe,
    PluralizePipe,
    TruncateStringPipe,
    GroupByNestedKeyPipe,
    ConvertNewlinePipe,
  ],
})
export class HandlerPipesModule {}
