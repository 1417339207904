import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'handler-announcements-remove-modal',
  templateUrl: './announcements-remove-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementsRemoveModalComponent {
  @Output() removeEvent = new EventEmitter<void>();
  @Output() closeEvent = new EventEmitter<void>();

  removeClicked = () => this.removeEvent.emit();

  close(): void {
    this.closeEvent.emit();
  }
}
