import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IDIcon, IDLink, IDLinkRoute, IDLinkWeb, IDMobileMenuItem, IDMobileMenuItemProps } from '@inera/ids-angular';
import { ProfessionHandlerConstantProperty, ProfessionHandlerConstantPropertyService } from '@sob/sob-resources';

@Component({
  selector: 'handler-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  /** Constructor */
  constructor(private professionHandlerConstantPropertyService: ProfessionHandlerConstantPropertyService) {
    this.professionHandlerConstantProperty = this.professionHandlerConstantPropertyService.getConstantProperties();
  }

  /** ProfessionHandlerConstantProperty contains links and labels for footer links */
  professionHandlerConstantProperty: ProfessionHandlerConstantProperty;

  /** Array of links displayed in footer on desktop */
  desktopFooterLinks: Array<IDLinkWeb> = [];

  /** Array of mobile menu items */
  mobileFooterLinks: Array<IDMobileMenuItem> = [];

  /** Array of links displayed at bottom of footer on both mobile/desktop */
  bottomFooterLinks: Array<IDLinkWeb> = [];

  /** @todo check icons with Hobring  */
  chevronIcon: IDIcon = new IDIcon({ name: 'chevron-bold', color: 'var(--footer-link_color)' });
  externalIcon: IDIcon = new IDIcon({ name: 'external', color: 'var(--footer-link_color)', size: 'xs'});
  externalIconSubFooter: IDIcon = new IDIcon({ name: 'external', color: 'var(--focus-outline-bright_color)', size: 'xs'});

  /** OnInit implementation */
  ngOnInit(): void {
    this.setFooterLinks();
  }

  /** Set values for footer links */
  setFooterLinks(): void {
    this.desktopFooterLinks = [
      new IDLinkWeb({
        label: this.professionHandlerConstantProperty.manualAndSupportDocumentLabel,
        url: this.professionHandlerConstantProperty.manualAndSupportDocumentUrl,
        target: '_blank'
      }),
      new IDLinkWeb({
        label: this.professionHandlerConstantProperty.technicalSupportLabel,
        url: this.professionHandlerConstantProperty.technicalSupportUrl,
        target: '_blank'
      })
    ];

    this.createFooterBottomLinks(); // check mobile
    this.createMobileLinks();
  }

  isIDLinkWeb = (link: IDLink | IDLinkWeb): link is IDLinkWeb => link instanceof IDLinkWeb;
  isIDLinkRoute = (link: IDLink | IDLinkRoute): link is IDLinkRoute => link instanceof IDLinkRoute;

  /** Helper method to generate footer bottom links */
  private createFooterBottomLinks(): void {
    this.bottomFooterLinks = [
      new IDLinkWeb({
        label: this.professionHandlerConstantProperty.cookiePolicyLabel,
        url: this.professionHandlerConstantProperty.cookiePolicyUrl,
        target: '_blank'
      })
    ];
  }

  /**
   * Helper method to generate mobile links for footer.
   * @todo check if we should add cookie-policy links here.
   */
  private createMobileLinks(): void {
    this.mobileFooterLinks = this.desktopFooterLinks.map(link => {
      const linkProps: IDMobileMenuItemProps = {
        link: new IDLinkWeb({ label: link.label, url: link.id }),
        secondary: true
      };
      const mobileLink: IDMobileMenuItem = new IDMobileMenuItem(linkProps);

      return mobileLink;
    });
  }

  /** TrackByFn index helper method */
  trackByFn(index: number, item: any): void {
    return item.id ? item.id : index;
  }
}
