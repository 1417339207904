<ng-container #mainModal>
  <inera-modal-content
    id="switch-care-provider-modal"
    modalAccessibilityLabelBy="switch-care-provider-modal"
    [hasCloseButton]="true"
    [checkNrOfButtonsToShowCloseButton]="false"
    [reference]="modalRef"
    (didClickCloseButton)="close()"
    aria-label="Växla vårdgivare">
    <div modal-body-content>
      <h1 class="modal-title h3">Växla vårdgivare</h1>
      <span class="text-lg">
        <strong>Aktuell vårdgivare:</strong>
        {{ handler.unit.name }}
      </span>
      <span>
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="d-flex flex-row mt-6">
            <inera-select
              class="col-8 ps-0"
              labelText="Välj vårdgivare att växla till"
              [selectOptions]="careProviderOptions"
              inputIdentifier="unitHsaid">
            </inera-select>
          </div>
          <div class="d-flex justify-content-center mt-5">
            <inera-button
              buttonTitle="Avbryt"
              textClass="text-uppercase"
              colorType="outline-primary"
              sizeClass="md"
              marginClass="me-md-6 mb-4 mb-md-0"
              [isPillShaped]="true"
              [responsive]="true"
              (buttonMethod)="close()">
            </inera-button>
            <inera-submit-button buttonType="submit" buttonLabel="VÄXLA VÅRDGIVARE"> </inera-submit-button>
          </div>
        </form>
      </span>
    </div>
  </inera-modal-content>
</ng-container>
