import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from 'src/app/services/destroy.service';
import { trackByFn } from 'src/app/utils/track-by.utils';

@Component({
  selector: 'handler-pagination',
  template: `
    <form [formGroup]="form">
      <div class="d-flex justify-content-end align-items-center">
        <p class="mb-0 lead-sm" aria-hidden="true">Rader per sida:</p>
        <id-select class="ms-2">
          <label for="limit-select" class="visually-hidden">Rader per sida</label>
          <select id="limit-select" formControlName="limit">
            <option *ngFor="let option of limitOptions; trackBy: trackByOption" [value]="option">{{ option }}</option>
          </select>
        </id-select>
        <span class="mb-0 ms-8">{{ displayRange }}</span>
        <button slot="trigger" class="btn btn-no-style ms-4" (click)="onPageDecrement()" aria-label="Till föregående sida">
          <id-icon name="chevron" size="s" rotate="180"></id-icon>
        </button>
        <button slot="trigger" class="btn btn-no-style ms-4" (click)="onPageIncrement()" aria-label="Till nästa sida">
          <id-icon name="chevron" size="s"></id-icon>
        </button>
      </div>
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class PaginationComponent implements OnInit {
  form = this.fb.group({
    limit: [null],
    offset: [0],
  });
  private _limitOptions: number[];
  private _totalItems: number;

  @Input() set limitOptions(options: number[]) {
    this._limitOptions = options;
  }

  @Input() set limit(limit: number) {
    this.form.patchValue({ limit }, { emitEvent: false });
  }

  @Input() set offset(offset: number) {
    this.form.patchValue({ offset }, { emitEvent: false });
  }

  @Input() set totalItems(count: number) {
    this._totalItems = count;
  }

  @Output() paginationOptionChangeEvent = new EventEmitter<{ limit: number; offset: number }>();

  get limitOptions(): number[] {
    return this._limitOptions;
  }

  get totalItems() {
    return this._totalItems;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private destroy$: DestroyService
  ) {}

  ngOnInit() {
    this.subscribeToFormValueChanges();
  }

  private subscribeToFormValueChanges() {
    this.form.valueChanges.pipe<{ limit: number; offset: number }>(takeUntil(this.destroy$)).subscribe(val => {
      if (val.limit >= this._totalItems) {
        this.paginationOptionChangeEvent.emit({ limit: val.limit, offset: 0 });
        return;
      }
      this.paginationOptionChangeEvent.emit(val);
    });
  }

  onPageIncrement() {
    if (!this.hasNextPage) return;
    this.form.patchValue({ offset: this.offset + this.limit });
  }

  onPageDecrement() {
    if (!this.hasPrevPage) return;
    this.form.patchValue({ offset: this.offset < this.limit ? 0 : this.offset - this.limit });
  }

  get limit() {
    return Number(this.form.value.limit);
  }

  get offset() {
    return Number(this.form.value.offset);
  }

  get hasNextPage() {
    return this.offset + this.limit < this.totalItems;
  }

  get hasPrevPage() {
    return this.offset > 0;
  }

  get displayRange() {
    return `${this.offset + 1} - ${Math.min(this.offset + this.limit, this.totalItems)} av ${this.totalItems}`;
  }

  trackByOption = trackByFn<number>(item => item);
}
