import { Pipe, PipeTransform } from '@angular/core';
import { ProcessInstanceStatus } from '@sob/sob-resources';

type Invariants = 'singular' | 'plural';
type StatusLabelDictionaryType = { [key: string]: { [invariant in Invariants]: string } };

@Pipe({ name: 'instanceStatusLabel' })
export class InstanceStatusLabelPipe implements PipeTransform {
  private labels: StatusLabelDictionaryType = {
    [ProcessInstanceStatus.INPROGRESS]: {
      singular: 'Pågående',
      plural: 'Pågående',
    },
    [ProcessInstanceStatus.MAINTAIN]: {
      singular: 'Vidmakthållande',
      plural: 'Vidmakthållna',
    },
    [ProcessInstanceStatus.HANDOVER]: {
      singular: 'Överlämnad',
      plural: 'Överlämningar',
    },
    [ProcessInstanceStatus.DONE]: {
      singular: 'Avklarad',
      plural: 'Avslutade',
    },
  };

  transform(status: string, invariant: Invariants) {
    return this.labels[status]?.[invariant] ?? 'Okänd';
  }
}
