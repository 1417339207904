<id-tooltip>
  <button slot="trigger" class="btn btn-no-style d-flex flex-row align-items-center" (click)="toggleSort()" [attr.aria-label]="ariaLabel">
    <strong class="me-1">{{ columnName }}</strong>
    <id-icon *ngIf="isSelectedColumn" name="arrow" size="xs" [rotate]="direction === 'ASC' ? '270' : '90'"></id-icon>
    <id-icon *ngIf="!isSelectedColumn" name="swap" rotate="90" size="s" height="17" width="17"></id-icon>
  </button>
  <div slot="tooltip">
    Sortera listan efter {{ columnName.toLowerCase() }} i {{ direction === 'DESC' ? 'stigande' : 'fallande' }} ordning
  </div>
</id-tooltip>
