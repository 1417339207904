<ng-container *ngIf="(handlerLoaded$ | async) && (hasExports$ | async)">
  <inera-accordion
    *ngIf="exports$ | async as exports"
    [shouldSavePanelStateToSessionStorage]="true"
    [shouldOpenAllOnInit]="true"
    sessionStorageKey="export-downloads"
    [openPanelIds]="'exports'">
    <inera-accordion-panel
      [panelTitle]="getHeadline((nonDownloadedExportCount$ | async)!)"
      [isRounded]="true"
      [hasTitleContentDivider]="true"
      [hasDropShadow]="true"
      [hasBottomBorder]="true"
      [hasLargeTopBorder]="true"
      marginClass="mt-6"
      chevronIconClasses="my-n3 mx-n2"
      paddingYClass="py-4"
      titlePaddingClasses="px-0"
      paddingXClass="px-4"
      headingTag="h1"
      [id]="'exports'">
      <ng-template ineraAccordionPanelContent>
        <ng-container *ngFor="let export of exports | sortBy: 'progress'; let first = first; let last = last; trackBy: exportTrackBy">
          <hr *ngIf="!first" />
          <div class="d-flex align-items-center mb-2">
            <p class="fw-bold mb-0 me-4">
              {{ export.templateName }}
            </p>

            <handler-export-download-tooltip [exportStatus]="export"></handler-export-download-tooltip>
          </div>

          <div class="d-flex align-items-center my-4">
            <strong>Status:</strong>
            <id-badge class="ms-4" [type]="export.progress === 'DOWNLOADED' ? 'success' : 'info'">
              {{ getExportStatusLabel(export.progress) }}
            </id-badge>
          </div>

          <button
            slot="trigger"
            [disabled]="!isDownloadable(export.progress)"
            class="btn btn-no-style d-flex align-items-center mb-4 link-hover"
            [ngClass]="isDownloadable(export.progress) ? 'link' : 'text-stone'"
            (click)="openDownloadModal(export)">
            <i class="download-icon me-1"></i>
            Ladda ner
          </button>
          <div [ngClass]="last ? 'mb-5' : 'mb-9'">
            <id-progressbar slot="trigger" [value]="export.percentage" title="Nedladdningsstatus"></id-progressbar>
          </div>
        </ng-container>
      </ng-template>
    </inera-accordion-panel>
  </inera-accordion>
</ng-container>

<ng-template #logoutModal let-modal>
  <inera-modal-content modalAccessibilityLabelBy="action-modal" id="action-modal" aria-label="Pågående export">
    <div modal-body-content>
      <h1 class="modal-title">Pågående export</h1>
      <p>
        Dina pågående exporter kommer att avbrytas om du loggar ut. Välj avbryt och vänta på att exporten laddas färdigt om du vill slutföra
        exporten. Om du inte vill behålla exporten kan du välja att logga ut.
      </p>
    </div>
    <inera-modal-button
      buttonTitle="Avbryt"
      customClasses="px-6 text-uppercase"
      colorType="outline-primary"
      (didClickModalButton)="onClose()">
    </inera-modal-button>
    <inera-modal-button buttonTitle="Logga ut" customClasses="px-6 text-uppercase" colorType="primary" (didClickModalButton)="onLogout()">
    </inera-modal-button>
  </inera-modal-content>
</ng-template>

<ng-template #downloadModal let-modal>
  <inera-modal-content
    *ngIf="exportToBeDownloaded"
    modalAccessibilityLabelBy="action-modal"
    id="action-modal"
    aria-label="Ladda ner fil till datorn">
    <div modal-body-content>
      <h1 class="modal-title">Ladda ner fil till datorn</h1>
      <p><strong>Moment i exporten:</strong>{{ ' ' + exportToBeDownloaded.processCount }}</p>
      <p><strong>Vald exportmall:</strong>{{ ' ' + exportToBeDownloaded.templateName }}</p>
      <p><strong>Exportformat:</strong>{{ ' XML' }}</p>
      <id-alert headline="Observera!" type="attention">
        <p>
          Vårdgivaren är ansvarig för att det finns rutiner för hur den exporterade filen hanteras då den kan innehålla patientuppgifter.
        </p>
        <p>All personuppgiftsbehandling måste ha laglig grund.</p>
        <p>När du som dataadministratör exporterar filen är du ansvarig för att rutinen följs.</p>
      </id-alert>
    </div>
    <inera-modal-button
      buttonTitle="Avbryt"
      customClasses="px-6 text-uppercase"
      colorType="outline-primary"
      [isDisabled]="(loading$ | async)!"
      (didClickModalButton)="closeModal()">
    </inera-modal-button>
    <inera-modal-button
      buttonTitle="Ladda ner"
      customClasses="px-6 text-uppercase"
      colorType="primary"
      [isDisabled]="(loading$ | async)!"
      (didClickModalButton)="downloadExport()">
    </inera-modal-button>
  </inera-modal-content>
</ng-template>
