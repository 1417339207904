import { Injectable } from '@angular/core';

@Injectable()
export class DownloadService {
  private downloadLink: HTMLAnchorElement | null = null;

  downloadFile(blob: Blob, fileName: string): void {
    this.downloadLink = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    this.downloadLink.href = url;
    this.downloadLink.download = fileName;

    this.downloadLink.click();
    this.downloadLink.remove();
    this.downloadLink = null;

    window.URL.revokeObjectURL(url);
  }
}
