<div *ngIf="conversation$ | async as conversation" style="position: fixed; bottom: 30px; z-index: 999">
    <id-card [fill2]="true" style="max-width: 400px">
      <div class="container">
        <div class="d-flex justify-content-between my-2">
          <h3>{{ conversation.subject }}</h3>
          <div class="d-flex flex-row align-items-center justify-content-end">
            <button class="btn btn-no-style ms-3 align-items-center" (click)="toggleModal()">
              <id-icon name="minus" size="s" class="border-0"></id-icon>
            </button>
            <button class="btn btn-no-style me-3 ms-3" (click)="closeModal()"><id-icon name="close-bold" size="s"></id-icon></button>
          </div>
        </div>
        <hr *ngIf="(minimized$ | async) === false" />
        <sob-conversation
          *ngIf="(minimized$ | async) === false"
          [conversation]="conversation"
          [activeIds]="[conversation.id]"
          [userRole]="userRole"
          [processInstance]="processInstance"
          [isHandlerMode]="true"
          [isModal]="true"
          class="w-auto">
        </sob-conversation>
      </div>
    </id-card>
</div>
