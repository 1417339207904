import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, Form } from '@sob/sob-resources';
import { MeasurementBattery, ScheduledMeasurementBattery } from './measurement-batteries.types';

@Injectable()
export class MeasurementBatteryHandlerService {
  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT) private readonly env: Environment
  ) {}

  getMeasurementBatteries(processInstanceId: string) {
    return this.http.get<MeasurementBattery[]>(`${this.env.baseApiUrl}/processes/${processInstanceId}/measurementbatteries`);
  }

  cancelMeasurementBattery(battery: MeasurementBattery, processInstanceId: number) {
    return this.http.post<MeasurementBattery>(
      `${this.env.baseApiUrl}/processes/${processInstanceId}/measurementbatteries/${battery.id}/cancel`,
      battery
    );
  }

  startExtraMeasurement(battery: ScheduledMeasurementBattery, processInstanceId: number, measurementBatteryInstanceId: number) {
    const url = `${this.env.baseApiUrl}/processes/${processInstanceId}/measurementbatteries/${measurementBatteryInstanceId}`;
    return this.http.post<ScheduledMeasurementBattery>(url, battery);
  }

  editScheduled(processInstanceId: number, scheduleId: number, payload: ScheduledMeasurementBattery) {
    const url = `${this.env.baseApiUrl}/processes/${processInstanceId}/measurementbatteries/schedule/${scheduleId}`;
    return this.http.put<ScheduledMeasurementBattery>(url, payload);
  }

  deleteScheduled(processInstanceId: number, scheduleId: number) {
    const url = `${this.env.baseApiUrl}/processes/${processInstanceId}/measurementbatteries/schedule/${scheduleId}`;
    return this.http.delete<ScheduledMeasurementBattery>(url);
  }

  getScheduledMeasurementBatteries(processInstanceId: number) {
    return this.http.get<ScheduledMeasurementBattery[]>(
      `${this.env.baseApiUrl}/processes/${processInstanceId}/measurementbatteries/schedule`
    );
  }

  getFormCollections(processInstanceId: number) {
    return this.http.get<Form[]>(`${this.env.baseApiUrl}/processes/${processInstanceId}/measurementbatteries/formcollections`);
  }

  scheduleMeasurementBattery(processInstanceId: number, payload: Omit<ScheduledMeasurementBattery, 'id'>) {
    const url = `${this.env.baseApiUrl}/processes/${processInstanceId}/measurementbatteries/schedule`;
    return this.http.post<ScheduledMeasurementBattery>(url, payload);
  }
}
