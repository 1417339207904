const locale = 'sv-SE';

export const compareString =
  <T>(selector: (value: T) => string) =>
  (a: T, b: T): number =>
    selector(a).localeCompare(selector(b), locale);

export const compareDate =
  <T>(selector: (value: T) => Date) =>
  (a: T, b: T): number =>
    selector(a) < selector(b) ? -1 : selector(a) > selector(b) ? 1 : 0;

export const compareObjects = <T>(a: T, b: T) => JSON.stringify(a) === JSON.stringify(b);
