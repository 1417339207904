import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { ProfessionLoginComponent } from '@sob/inera-session';
import { ErrorComponent, RedirectComponent } from '@sob/sob-resources';
import { logoutGuard } from './guards/logout.guard';
import { pageGuardCanLoad } from './guards/page.guard';
import { redirectGuard } from './guards/redirect.guard';
import { Page } from './utils/role.utils';

const routes: Routes = [
  { path: 'login', component: ProfessionLoginComponent },
  { path: 'sob-handler-web/logout', canActivate: [logoutGuard], component: RedirectComponent },
  { path: '', canActivate: [redirectGuard], component: RedirectComponent },
  {
    path: 'administration',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.ADMINISTRATION },
    loadChildren: () => import('./pages/administration/administration.module').then(x => x.AdministrationModule),
  },
  {
    path: 'care-provider-administration',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.VARDGIVARADMINISTRATION },
    loadChildren: () => import('./pages/care-provider-admin/care-provider-admin.module').then(x => x.CareProviderAdminModule),
  },
  {
    path: 'content-directory',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.INNEHALLSKATALOG },
    loadChildren: () => import('./pages/content-directory/content-directory.module').then(x => x.ContentDirectoryModule),
  },
  {
    path: 'national-content-directory',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.NATIONELL_INNEHALLSKATALOG },
    loadChildren: () => import('./pages/content-directory/content-directory.module').then(x => x.ContentDirectoryModule),
  },
  {
    path: 'debugging',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.FELSOKNING },
    loadChildren: () => import('./pages/debugging/debugging.module').then(x => x.DebuggingModule),
  },
  {
    path: 'handover',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.OVERLAMNINGAR },
    loadChildren: () => import('./pages/handover/handover.module').then(x => x.HandoverModule),
  },
  {
    path: 'national-administration',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.NATIONELL_ADMINISTRATION },
    loadChildren: () => import('./pages/national-admin/national-admin.module').then(x => x.NationalAdminModule),
  },
  {
    path: 'personnel',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.PERSONAL },
    loadChildren: () => import('./pages/personnel/personnel.module').then(x => x.PersonnelModule),
  },
  {
    path: 'processes',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.HANTERA_MOMENT },
    loadChildren: () => import('./pages/processes/processes.module').then(x => x.ProcessesModule),
  },
  {
    path: 'services-dashboard',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.TJANSTER },
    loadChildren: () => import('./pages/services-dashboard/services-dashboard.module').then(x => x.ServicesDashboardModule),
  },
  {
    path: 'operation-info',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.DRIFTINFO },
    loadChildren: () => import('./pages/operation-info/operation-info.module').then(x => x.OperationInfoModule),
  },
  {
    path: 'profile',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.MIN_PROFIL },
    loadChildren: () => import('./pages/profile/profile.module').then(x => x.ProfileModule),
  },
  {
    path: 'settings',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.INSTALLNINGAR },
    loadChildren: () => import('./pages/settings/settings.module').then(x => x.SettingsModule),
  },
  {
    path: 'statistics',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.STATISTIK },
    loadChildren: () => import('./pages/statistics/statistics.module').then(x => x.StatisticsModule),
  },
  {
    path: 'unit-administration',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.UTGIVARENHETER },
    loadChildren: () => import('./pages/unit-admin/unit-admin.module').then(x => x.UnitAdminModule),
  },
  {
    path: 'user-groups',
    canLoad: [pageGuardCanLoad],
    data: { page: Page.ANVANDARGRUPPER },
    loadChildren: () => import('./pages/user-groups/user-groups.module').then(x => x.UserGroupsModule),
  },
  { path: 'error', component: ErrorComponent },
  { path: '**', component: ErrorComponent },
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
