<inera-modal-content
  (didClickCloseButton)="close()"
  modalAccessibilityLabelBy="remove-announcement-modal"
  id="remove-announcement-modal"
  aria-label="Ta bort meddelande">
  <div modal-body-content>
    <div class="d-flex flex-row align-items-center">
      <h1 class="modal-title h1">Ta bort meddelande</h1>
    </div>
    <p>Är du säker på att du vill ta bort detta meddelande? Denna handling går ej att ångra.</p>
  </div>
  <inera-modal-button buttonTitle="Avbryt" customClasses="px-6 text-uppercase" colorType="outline-primary" (didClickModalButton)="close()">
  </inera-modal-button>
  <inera-modal-button
    buttonTitle="Ta bort meddelande"
    customClasses="px-6 text-uppercase"
    colorType="primary"
    (didClickModalButton)="removeClicked()">
  </inera-modal-button>
</inera-modal-content>
