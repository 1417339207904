<ng-container *ngIf="loaded; else loading">
  <handler-announcements-list
    [announcements]="sortedAnnouncements"
    [showDirectionOptions]="showDirectionOptions"
    (editEvent)="editAnnouncement($event.announcementId, $event.announcementData)"
    (removeEvent)="removeAnnouncement($event)">
  </handler-announcements-list>

  <div class="mt-5">
    <id-button size="l" [secondary]="false" (onClicked)="openAddAnnouncementModal()">Lägg till meddelande</id-button>
  </div>
</ng-container>

<ng-template #addAnnouncementModal>
  <handler-announcements-manage-modal
    [showDirectionOptions]="showDirectionOptions"
    [editMode]="false"
    [informationText]="informationText"
    (closeEvent)="closeModal()"
    (actionEvent)="addAnnouncement($event)">
  </handler-announcements-manage-modal>
</ng-template>

<ng-template #loading>
  <div class="my-10 d-flex justify-content-center">
    <inera-loader [isVertical]="false" loaderColorClass="primary" loaderText="Laddar innehåll..."></inera-loader>
  </div>
</ng-template>
