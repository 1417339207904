import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Form, FormService, Section, SectionService, Step, StepVisibilityService } from '@sob/sob-resources';
import { PreviewFormService } from 'src/app/services/preview-form.service';
import { mapSections } from './manage-modules.utils';

export class InitForms {
  static readonly type = '[ManageModules] InitForms';
  constructor(
    public processInstanceId: number,
    public moduleId: number
  ) {}
}

export class LoadForms {
  static readonly type = '[ManageModules] LoadForms';
  constructor(
    public processInstanceId: number,
    public moduleId: number
  ) {}
}

export class LoadSections {
  static readonly type = '[ManageModules] LoadSections';
  constructor(
    public processInstanceId: number,
    public moduleId: number
  ) {}
}

export class UpdateStepVisibility {
  static readonly type = '[ManageModules] UpdateStepVisibility';
  constructor(
    public processInstanceId: number,
    public stepId: number,
    public visible: boolean
  ) {}
}

export class MarkFormAsRead {
  static readonly type = '[ManageModules] MarkFormAsRead';
  constructor(
    public processInstanceId: number,
    public moduleId: number,
    public form: Form
  ) {}
}

export class ClearManageModulesState {
  static readonly type = '[ManageModules] ClearState';
}

export interface ManageModulesStateModel {
  forms: {
    [moduleId: number]: Form[];
  };
  sections: {
    [moduleId: number]: Section[];
  };
}

const initialState: ManageModulesStateModel = {
  forms: {},
  sections: {},
};

@Injectable()
@State<ManageModulesStateModel>({
  name: 'manageModules',
  defaults: initialState,
})
export class ManageModulesState {
  constructor(
    private formService: FormService,
    private sectionService: SectionService,
    private stepVisibilityService: StepVisibilityService,
    private previewFormService: PreviewFormService
  ) {}

  static moduleForms(moduleId: number) {
    return createSelector([ManageModulesState], (state: ManageModulesStateModel) => {
      return state.forms[moduleId].filter(x => !x.step.length).map(form => new Form().deserialize(form));
    });
  }

  static sections = (moduleId: number) =>
    createSelector([ManageModulesState], (state: ManageModulesStateModel) =>
      mapSections(
        state.sections[moduleId],
        state.forms[moduleId].map(form => new Form().deserialize(form))
      )
    );

  @Selector()
  static disabledStepIds(state: ManageModulesStateModel) {
    return Object.keys(state.sections).reduce<number[]>((acc, moduleId) => {
      const sections = state.sections[Number(moduleId)];
      const steps = sections.reduce<Step[]>((acc, section) => acc.concat(section.steps), []);
      const visibleStepIds = steps.filter(step => step.visible).map(step => step.id);
      const uniqueVisibleStepIds = Array.from(new Set(visibleStepIds));
      if (uniqueVisibleStepIds.length === 1) {
        acc.push(visibleStepIds[0]);
      }
      return acc;
    }, []);
  }

  @Selector()
  static getSections(state: ManageModulesStateModel) {
    return state.sections;
  }

  @Action(InitForms)
  async initForms(context: StateContext<ManageModulesStateModel>, { moduleId, processInstanceId }: InitForms) {
    const forms = await this.formService.readFormsFromProcessModule(processInstanceId.toString(), moduleId.toString()).toPromise();
    context.patchState({ forms: { ...context.getState().forms, [moduleId]: forms } });
  }

  @Action(LoadForms)
  async loadForms(context: StateContext<ManageModulesStateModel>, { moduleId, processInstanceId }: LoadForms) {
    const forms = await this.formService.readFormsFromProcessModule(processInstanceId.toString(), moduleId.toString()).toPromise();
    context.patchState({ forms: { ...context.getState().forms, [moduleId]: forms } });
  }

  @Action(LoadSections)
  async loadSections(context: StateContext<ManageModulesStateModel>, { moduleId, processInstanceId }: LoadSections) {
    if (context.getState().sections[moduleId]) {
      return;
    }

    const sections = await this.sectionService.list([processInstanceId.toString()], `/modules/${moduleId}/sections`).toPromise();
    context.patchState({ sections: { ...context.getState().sections, [moduleId]: sections } });
  }

  @Action(UpdateStepVisibility)
  async updateStepVisibility(context: StateContext<ManageModulesStateModel>, { stepId, visible, processInstanceId }: UpdateStepVisibility) {
    await this.stepVisibilityService.update([processInstanceId.toString(), stepId.toString()], { visible }).toPromise();

    const sections = context.getState().sections;
    const moduleSections = Object.keys(sections).reduce<{ [moduleId: string]: Section[] }>((acc, moduleId) => {
      const updatedSections = sections[Number(moduleId)].map(s => {
        const updatedSteps = s.steps.map(step => ({ ...step, visible: step.id === stepId ? visible : step.visible }));
        return { ...s, steps: updatedSteps } as Section;
      });
      acc[moduleId] = updatedSections;
      return acc;
    }, {});
    
    context.patchState({ sections: moduleSections });
  }

  @Action(ClearManageModulesState)
  clearState(context: StateContext<ManageModulesStateModel>) {
    context.setState(initialState);
  }

  @Action(MarkFormAsRead)
  async markAsRead(context: StateContext<ManageModulesStateModel>, { moduleId, form, processInstanceId }: MarkFormAsRead) {
    await this.previewFormService.markFormAsRead(processInstanceId.toString(), form.id.toString()).toPromise();
    context.dispatch(new LoadForms(processInstanceId, moduleId));
  }
}
