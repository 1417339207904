import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Handler, HandlerService, ProfessionRole } from '@sob/sob-resources';
import { hasRole } from 'src/app/utils/role.utils';
import { ProcessMultiNotifications, ProcessMultiService } from '../services/process-multi.service';
import { LoadSettings } from './system.state';

export class LoadHandler {
  static readonly type = '[Handler] Load';
}

export class LoadNotifications {
  static readonly type = '[Handler] LoadNotifications';
}

export class UpdateHandler {
  static readonly type = '[Handler] Update';
  constructor(public handler: Handler) {}
}

export interface HandlerStateModel {
  handler: Handler | null;
  loaded: boolean;
  notifications: ProcessMultiNotifications | null;
}

@Injectable()
@State<HandlerStateModel>({
  name: 'handler',
  defaults: {
    handler: null,
    loaded: false,
    notifications: null,
  },
})
export class HandlerState implements NgxsOnInit {
  constructor(
    private handlerService: HandlerService,
    private processMultiService: ProcessMultiService
  ) {}

  @Selector()
  static handler(state: HandlerStateModel) {
    if (!state.handler) return null;
    return new Handler().deserialize(state.handler);
  }

  @Selector()
  static isUserManager(state: HandlerStateModel) {
    if (!state.handler) return null;
    return new Handler().deserialize(state.handler).roles.includes(ProfessionRole.USER_MANAGER);
  }

  @Selector()
  static isNationalAdmin(state: HandlerStateModel) {
    if (!state.handler) return null;
    return new Handler().deserialize(state.handler).roles.includes(ProfessionRole.NATIONAL_MANAGER);
  }

  @Selector()
  static isPublishUnit(state: HandlerStateModel) {
    if (!state.handler) return null;
    return new Handler().deserialize(state.handler).unit.publishUnit;
  }

  @Selector()
  static loaded(state: HandlerStateModel) {
    return state.loaded;
  }

  @Selector()
  static notifications(state: HandlerStateModel) {
    return state.notifications ?? { prioritized: { handler: 0, unit: 0 }, handovers: 0 };
  }

  ngxsOnInit(context: StateContext<HandlerStateModel>) {
    this.handlerService.currentHandlerSubject.subscribe(handler => context.patchState({ handler }));
  }

  @Action(LoadHandler)
  async loadHandler(context: StateContext<HandlerStateModel>) {
    const handler = await this.handlerService.read().toPromise();
    await context.dispatch(new LoadSettings()).toPromise();
    context.patchState({ handler, loaded: true });
  }

  @Action(LoadNotifications)
  async loadNotifications(context: StateContext<HandlerStateModel>) {
    const handler = context.getState().handler;
    if (!handler) return;
    const notifications = hasRole(handler, [ProfessionRole.HANDLER, ProfessionRole.RESIDENT_MANAGER])
      ? await this.processMultiService.getNotifications().toPromise()
      : null;

    context.patchState({ notifications });
  }

  @Action(UpdateHandler)
  async updateHandler(context: StateContext<HandlerStateModel>, { handler }: UpdateHandler) {
    const updatedHandler = await this.handlerService.update([], handler).toPromise();
    context.patchState({ handler: updatedHandler });
  }
}
