import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IDCardModule, IDIconModule } from '@inera/ids-angular';
import { ConversationModule } from '@sob/sob-message';
import { ProcessConversationsModule } from 'src/app/pages/processes/details/manage/conversations/process-conversations.module';
import { ConversationModalComponent } from './conversation-modal.component';

@NgModule({
  declarations: [ConversationModalComponent],
  imports: [CommonModule, IDCardModule, IDIconModule, ConversationModule, ProcessConversationsModule],
  exports: [ConversationModalComponent],
})
export class ConversationModalModule {}
