import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { SelectOption } from '@sob/inera-component-library';
import * as moment from 'moment';
import { Announcement, AnnouncementData } from 'src/app/models/announcement';
import { formatFormDate } from 'src/app/utils/format-date.utils';
import { dateTimeIntervalValidator } from 'src/app/validators/date-interval.validator';

@Component({
  selector: 'handler-announcements-manage-modal',
  templateUrl: './announcements-manage-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementsManageModalComponent implements OnInit, OnDestroy {
  form = this.fb.group(
    {
      direction: ['PERSONELL', Validators.required],
      startDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endDate: ['', Validators.required],
      endTime: ['', Validators.required],
      message: ['', Validators.required],
    },
    { validators: dateTimeIntervalValidator() }
  );
  directionOptions = [
    { alternativeValue: 'Personal', answerAlternativeNumber: 'PERSONELL' },
    { alternativeValue: 'Invånare', answerAlternativeNumber: 'RESIDENT' },
  ] as SelectOption[];
  minDate: string = formatFormDate(new Date());

  @Input() editMode = false;
  @Input() showDirectionOptions: boolean;
  @Input() announcement: Announcement | undefined;
  @Input() informationText: string;

  @Output() actionEvent = new EventEmitter<AnnouncementData>();
  @Output() closeEvent = new EventEmitter<void>();
  @Output() clearEvent = new EventEmitter<void>();

  constructor(private fb: UntypedFormBuilder) {}

  get hasError() {
    return this.form.hasError('endDateBeforeStartDate');
  }

  get startTimeHasError() {
    const startTimeControl = this.form.get('startTime') as UntypedFormControl;
    return startTimeControl.touched && startTimeControl.invalid;
  }

  get endTimeHasError() {
    const endTimeControl = this.form.get('endTime') as UntypedFormControl;
    return endTimeControl.touched && endTimeControl.invalid;
  }

  ngOnInit() {
    if (this.announcement) {
      this.form.patchValue({
        direction: this.announcement.direction,
        startDate: moment(new Date(this.announcement.startDate)).format('YYYY-MM-DD'),
        startTime: moment(new Date(this.announcement.startDate)).format('HH:mm'),
        endDate: moment(new Date(this.announcement.endDate)).format('YYYY-MM-DD'),
        endTime: moment(new Date(this.announcement.endDate)).format('HH:mm'),
        message: this.announcement.text,
      });
    }
  }

  ngOnDestroy() {
    this.reset();
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const { direction, startDate, startTime, endDate, endTime, message } = this.form.value;
    const announcementData = {
      direction: direction,
      startDate: moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ssZZ'),
      endDate: moment(`${endDate} ${endTime}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ssZZ'),
      text: message,
    } as AnnouncementData;

    this.actionEvent.emit(announcementData);
  }

  close() {
    this.closeEvent.emit();
  }

  reset() {
    this.form.markAsUntouched();
    this.form.reset();
  }
}
