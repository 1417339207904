import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Conversation, ProcessInstance, UserRole } from '@sob/sob-resources';
import { Observable } from 'rxjs';
import { ProcessDetailsState } from 'src/app/pages/processes/details/states/process-details.state';
import { DestroyService } from 'src/app/services/destroy.service';
import { CloseConversationModal, ConversationsState, ToggleConversationModal } from 'src/app/states/conversations.state';

@Component({
  selector: 'handler-conversation-modal',
  templateUrl: './conversation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  styles: [
    `
      .container {
        max-height: calc(100vh - 5rem);
        overflow-y: auto;
      }
    `,
  ],
})
export class ConversationModalComponent implements OnInit {
  processInstance: ProcessInstance;
  userRole = UserRole.HANDLER;

  @Select(ConversationsState.modalConversation) conversation$: Observable<Conversation | null>;
  @Select(ConversationsState.modalIsMinimized) minimized$: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);
  }

  toggleModal() {
    this.store.dispatch(new ToggleConversationModal());
  }

  closeModal() {
    this.store.dispatch(new CloseConversationModal());
  }
}
