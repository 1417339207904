import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Page } from 'src/app/utils/role.utils';
import { trackByFn } from 'src/app/utils/track-by.utils';

export type SelectedViewType = Page.HANTERA_MOMENT | Page.DESIGNER;
export interface SelectedViewMenuItem {
  value: SelectedViewType;
  name: string;
}
@Component({
  selector: 'handler-switch-current-view',
  templateUrl: './switch-current-view.component.html',
  styleUrls: ['./switch-current-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchCurrentViewComponent implements OnInit {
  @Input() selectedView: SelectedViewType;

  public menuOptions: SelectedViewMenuItem[] = [
    {
      value: Page.HANTERA_MOMENT,
      name: 'Hantera moment',
    },
    {
      value: Page.DESIGNER,
      name: 'Designer',
    },
  ];

  public trackBy = trackByFn<SelectedViewMenuItem>(item => item.value);

  ngOnInit(): void {
    // sort the list so the selected option is always on top
    this.menuOptions = this.menuOptions.sort(a => {
      return a.value === this.selectedView.toString() ? -1 : 1;
    });
  }

  public onSelectionChange(event: Event): void {
    if (!event.target) {
      return;
    }
    this.routeUserToDestination((event as any).target.value);
  }

  private routeUserToDestination(destination: SelectedViewType) {
    switch (destination) {
      case Page.DESIGNER:
        window.location.replace(`${document.location.protocol}//${document.location.hostname}/designer/overview/processes`);
        break;

      case Page.HANTERA_MOMENT:
      default:
        break;
    }
  }
}
