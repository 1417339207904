import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { CommentEntityType, Conversation, Form, Step, UserRole } from '@sob/sob-resources';
import { Observable } from 'rxjs';
import { ConversationsState, OpenConversationModal } from 'src/app/states/conversations.state';
import { ProcessDetailsState } from '../../../states/process-details.state';

@Component({
  selector: 'handler-process-comments',
  templateUrl: './process-comments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessCommentsComponent {
  processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);
  comments: Conversation[] = [];
  userRole: UserRole = UserRole.HANDLER;
  useSeparator: boolean;

  @Select(ConversationsState.modalConversationId) modalConversationId$: Observable<number>;

  @Input() entity: Step | Form;
  @Input() entityType: CommentEntityType;
  @Input() entityId: number;
  @Input() subject: string;
  @Input() displayComments: boolean;

  constructor(private store: Store) {}

  get separatorForEntityType() {
    return (this.useSeparator = this.entityType === CommentEntityType.STEP);
  }

  get isResidentAccountInactivated() {
    return this.processInstance.resident.profileInactivated;
  }

  get isAgentAccountInactivated() {
    return this.processInstance.resident.agentProfilesInactivated;
  }

  openInModal(commentId: number) {
    this.store.dispatch(new OpenConversationModal(this.processInstance.id, commentId));
  }
}
