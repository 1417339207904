import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngxs/store';
import { NotificationService, ToastService } from '@sob/inera-component-library';
import { SessionConfig } from '@sob/inera-session';
import { FocusManagementService, GlobalAnnouncementStore } from '@sob/sob-resources';
import { Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ConversationsState } from './states/conversations.state';
import { HandlerState } from './states/handler.state';
import { SystemState } from './states/system.state';
import { getRouteData } from './utils/route.utils';

@Component({
  selector: 'handler-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  systemSettings$ = this.store.select(SystemState.settings);
  handler$ = this.store.select(HandlerState.handler);
  conversationModalIsOpen$ = this.store.select(ConversationsState.modalIsOpen);

  useFullWidth$ = this.router.events.pipe(
    filter((e): e is RoutesRecognized => e instanceof RoutesRecognized),
    map(e => getRouteData(e.state)?.useFullWidth ?? false)
  );
  containerWidth$ = combineLatest([this.useFullWidth$, this.conversationModalIsOpen$]).pipe(
    map(([useFullWidth, conversationModalIsOpen]) => {
      if (conversationModalIsOpen) {
        return 'col-8';
      }
      if (useFullWidth) {
        return 'col-12';
      }
      return 'col-10';
    })
  );

  /** Component constructor */
  constructor(
    private readonly focusManagementService: FocusManagementService,
    private readonly router: Router,
    private readonly sessionConfig: SessionConfig,
    private readonly store: Store,
    public readonly notificationService: NotificationService,
    public readonly toastService: ToastService,
    public readonly announcementStore: GlobalAnnouncementStore
  ) {}

  /** When the app component initializes */
  ngOnInit(): void {
    this.subscribeToNonErrorRouteActivation();
    this.subscribeToLogoutEventAndClearSessionStorage();
    this.notificationService.setUpNotificationSubject();
    this.toastService.setUpNotificationSubject();
  }

  private subscribeToNonErrorRouteActivation(): void {
    const routeSubscription = this.router.events
      .pipe(
        filter((event): event is ActivationEnd => event instanceof ActivationEnd),
        map(event => event.snapshot.routeConfig?.path === '**'),
        distinctUntilChanged(),
        filter(isErrorRoute => !isErrorRoute)
      )
      .subscribe(() => this.clearPreservedErrorData());

    this.subscriptions.add(routeSubscription);
  }

  private clearPreservedErrorData() {
    localStorage.removeItem('apiErrorObject');
  }

  /**
   * Method used to subscribe to router events, checks if url is '/sob-handler-web/logout',
   * if the case clear sessionStorageData, needed for clearing cached pagination info.
   */
  private subscribeToLogoutEventAndClearSessionStorage(): void {
    const routeEventSubscription = this.router.events.subscribe({
      next: event => {
        if (event instanceof NavigationStart && event.url === this.sessionConfig?.logoutPath) {
          sessionStorage.clear();
        }
      },
    });

    this.subscriptions.add(routeEventSubscription);
  }

  /** AfterViewInit lifecycle */
  ngAfterViewInit(): void {
    this.focusManagementService.setUpFocusManagement();
  }

  /** TrackByFn index helper method */
  trackByFn(index: number, item: any): void {
    return item.id ? item.id : index;
  }

  /** OnDestroy app lifecycle */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  updateReadStateForAnnouncements() {
    return localStorage.getItem('isAnnouncementExpanded') === 'true'
      ? this.announcementStore.updateReadStateForAnnouncements(false)
      : this.announcementStore.updateReadStateForAnnouncements(true);
  }
}
