import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertNewLine',
})
export class ConvertNewlinePipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/(.)\n/g, (_match, lastChar) => {
      return lastChar === '.' ? `${lastChar}<br>` : `${lastChar}.<br>`;
    });
  }
}
