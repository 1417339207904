import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { BehaviorSubject, ObservedValueOf } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, mergeAll, mergeMap, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { ExportStatusProgress } from 'src/app/pages/export/export.types';
import { DestroyService } from 'src/app/services/destroy.service';
import { DownloadService } from 'src/app/services/download.service';
import { HandlerState } from 'src/app/states/handler.state';
import { trackByFn } from 'src/app/utils/track-by.utils';
import { StoredExportStatus } from './../../pages/export/export.types';
import { DownloadExport, ExportDownloadState, HideLogoutModal, QueryExportStatus, ResetExportDownloads } from './export-download.state';
import { getExportStatusLabel, pollingStatuses } from './export-download.utils';

@Component({
  selector: 'handler-export-download',
  templateUrl: './export-download.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  styles: [
    `
      .link {
        text-decoration: underline;
        color: #396291;
        &:hover {
          color: #3b4266;
        }
      }
    `,
  ],
})
export class ExportDownloadComponent implements OnInit, OnDestroy {
  exportToBeDownloaded: StoredExportStatus | null = null;

  @ViewChild('logoutModal') logoutModalRef: NgbModalRef;
  @ViewChild('downloadModal') downloadModalRef: NgbModalRef;

  loading$ = new BehaviorSubject(false);
  handlerLoaded$ = this.store.select(HandlerState.loaded);
  hasExports$ = this.store.select(ExportDownloadState.hasExports);
  exports$ = this.store.select(ExportDownloadState.exports);
  nonDownloadedExportCount$ = this.store.select(ExportDownloadState.nonDownloadedExportCount);

  constructor(
    private store: Store,
    private modalService: NgbModal,
    private downloadService: DownloadService,
    private router: Router,
    private destroy$: DestroyService
  ) {}

  ngOnInit() {
    this.exports$
      .pipe(
        takeUntil(this.destroy$),
        startWith([]),
        pairwise(),
        map(([oldExports, newExports]) => newExports.filter(newItem => !oldExports.some(oldItem => oldItem.id === newItem.id))),
        mergeAll(),
        mergeMap(({ id }) => {
          return this.store.select(ExportDownloadState.export(id)).pipe(
            filter((exportItem): exportItem is StoredExportStatus => !!exportItem),
            delay(5000)
          );
        }),
        filter(exportItem => pollingStatuses.includes(exportItem.progress))
      )
      .subscribe(exportItem => this.store.dispatch(new QueryExportStatus(exportItem.id)));

    this.store
      .select(ExportDownloadState.logoutModalVisible)
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(showLogoutModal => {
        if (showLogoutModal) {
          this.openLogoutModal();
        } else {
          this.closeModal();
        }
      });
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetExportDownloads());
  }

  getExportStatusLabel = getExportStatusLabel;

  getHeadline(nonDownloadedExportCount: number) {
    return nonDownloadedExportCount > 0 ? 'Exporterar (' + nonDownloadedExportCount + 'st)' : 'Exporterar';
  }

  async downloadExport() {
    if (!this.exportToBeDownloaded) {
      return;
    }

    this.loading$.next(true);
    await this.store.dispatch(new DownloadExport(this.exportToBeDownloaded.id)).toPromise();
    this.loading$.next(false);
    this.closeModal();
  }

  onClose() {
    this.store.dispatch(new HideLogoutModal());
  }

  onLogout() {
    this.router.navigateByUrl('/sob-handler-web/logout?ignoreExports=true');
  }

  isDownloadable(status: ExportStatusProgress) {
    return status === 'DONE' || status === 'DOWNLOADED';
  }

  openDownloadModal(exportStatus: StoredExportStatus) {
    this.exportToBeDownloaded = exportStatus;
    const modalRef = this.modalService.open(this.downloadModalRef, {
      size: 'sm',
      centered: true,
    });
    modalRef.dismissed.pipe(takeUntil(this.destroy$)).subscribe(() => this.onClose());
  }

  private openLogoutModal() {
    const modalRef = this.modalService.open(this.logoutModalRef, {
      size: 'sm',
      centered: true,
    });
    modalRef.dismissed.pipe(takeUntil(this.destroy$)).subscribe(() => this.onClose());
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  exportTrackBy = trackByFn<ObservedValueOf<typeof this.exports$>[number]>(exportItem => exportItem.id);
}
