import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'agentTypeText' })
export class AgentTypeTextPipe implements PipeTransform {
  private agentTypeLabels: { [key: string]: string } = {
    GUARDIAN: 'vårdnadshavare',
  };

  transform(value: string): string {
    return this.agentTypeLabels[value] || value;
  }
}
