import { Handler } from '@sob/sob-resources';
import { SystemSettings } from 'src/app/states/system.state';
import { hasAccess, Page } from 'src/app/utils/role.utils';

export interface HeaderNavItemOptions {
  page: Page;
  label: string;
  route: string;
}

export interface HeaderNavItem {
  label: string;
  route: string;
}

export const navItemOptions: HeaderNavItemOptions[] = [
  {
    page: Page.HANTERA_MOMENT,
    label: 'Hantera moment',
    route: '/processes',
  },
  {
    page: Page.PERSONAL,
    label: 'Personal',
    route: '/personnel',
  },
  {
    page: Page.ANVANDARGRUPPER,
    label: 'Användargrupper',
    route: '/user-groups',
  },
  {
    page: Page.VARDGIVARADMINISTRATION,
    label: 'Vårdgivaradministration',
    route: '/care-provider-administration',
  },
  {
    page: Page.NATIONELL_ADMINISTRATION,
    label: 'Nationell administration',
    route: '/national-administration',
  },
  {
    page: Page.UTGIVARENHETER,
    label: 'Utgivarenheter',
    route: '/unit-administration',
  },
  {
    page: Page.STATISTIK,
    label: 'Statistik',
    route: '/statistics',
  },
  {
    page: Page.INNEHALLSKATALOG,
    label: 'Innehållskatalog',
    route: '/content-directory',
  },
  {
    page: Page.NATIONELL_INNEHALLSKATALOG,
    label: 'Innehållskatalogen',
    route: '/national-content-directory',
  },
  {
    page: Page.INSTALLNINGAR,
    label: 'Inställningar',
    route: '/settings',
  },
  {
    page: Page.MIN_PROFIL,
    label: 'Min profil',
    route: '/profile',
  },
  {
    page: Page.TJANSTER,
    label: 'Tjänster',
    route: '/services-dashboard',
  },
  {
    page: Page.DRIFTINFO,
    label: 'Driftinfo',
    route: '/operation-info',
  },
  {
    page: Page.ADMINISTRATION,
    label: 'Administration',
    route: '/administration',
  },
  {
    page: Page.FELSOKNING,
    label: 'Felsökning',
    route: '/debugging',
  },
];

export const getNavItems = (handler: Handler, systemSettings: SystemSettings) =>
  navItemOptions
    .filter(options => hasAccess(handler, systemSettings, options.page))
    .map<HeaderNavItem>(options => ({ label: options.label, route: options.route }));
