import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformUrlToLink',
})
export class TransformUrlToLinkPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const linkRegex = /https?:\/\/\S+|www\.\S+/gi;

    return value.replace(linkRegex, match => {
      const href = match.startsWith('www') ? `http://${match}` : match;
      return `<a href="${href}" target="_blank">
      ${match}
      <span class="visually-hidden">Öppnas i en ny flik.</span>
      <i class="open-external-icon ms-1 d-inline-block align-middle" aria-hidden="true"></i>
      </a>`;
    });
  }
}
