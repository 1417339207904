import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showAgeText',
})
export class ShowAgeTextPipe implements PipeTransform {
  transform(age: { from: number; to: number }): string {
    if (age.from && age.to) {
      return `${age.from} - ${age.to}`;
    } else if (age.from) {
      return `${age.from} och uppåt`;
    } else if (age.to) {
      return `0 - ${age.to}`;
    }
    return 'Alla';
  }
}
