import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, SystemSetting } from '@sob/sob-resources';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT) private readonly env: Environment
  ) {}

  getSettings() {
    return this.http.get<SystemSetting[]>(`${this.env.baseApiUrl}/systemsettings`);
  }
}
