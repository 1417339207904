import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@sob/sob-resources';
import { ExtendedEnvironment } from 'src/environments/extended-environment.model';
import { AclUnit } from '../models/acl-unit';
import { AclUser, Roles } from '../models/acl-user';
import { Handover } from '../models/handover';
import { User } from '../models/user';
import { Credentials } from '../pages/personnel/personnel.types';
import { PaginationOptions, UserGroup } from '../pages/user-groups/user-groups.types';

@Injectable({
  providedIn: 'root',
})
export class UnitService {
  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT) private readonly env: ExtendedEnvironment
  ) {}

  getOwnedUnits() {
    return this.http.get<AclUnit[]>(`${this.env.usermgmtApiUrl}/unit/owned`);
  }

  getUsersFromUnit(unitHsaId: string, active: boolean) {
    return this.http.get<AclUser[]>(`${this.env.usermgmtApiUrl}/unit/${unitHsaId}/users?status=${active ? 'ACTIVE' : 'INACTIVE'}`);
  }

  getUserFromUnit(unitHsaId: string, userHsaId: string) {
    return this.http.get<{ status: string[]; credentials: Credentials[] }>(
      `${this.env.usermgmtApiUrl}/unit/${unitHsaId}/hsasearch?personHsaId=${userHsaId}`
    );
  }

  getPendingHandoversForUnits(unitHsaId: string) {
    return this.http.get<Handover[]>(`${this.env.baseApiUrl}/unit/${unitHsaId}/handovers`);
  }

  getHandlersForUnit() {
    return this.http.get<User[]>(`${this.env.baseApiUrl}/unit/handlers`);
  }

  getDoneHandlersForUnit() {
    return this.http.get<User[]>(`${this.env.baseApiUrl}/unit/donehandlers`);
  }

  getUserGroupsFromUnit(unitHsaId: string, paginationOptions: PaginationOptions) {
    return this.http.get<UserGroup>(
      `${this.env.usermgmtApiUrl}/unit/${unitHsaId}/usergroup?limit=${paginationOptions.limit}&offset=${paginationOptions.offset}`
    );
  }

  addUserToUnit(unitHsaId: string, userHsaId: string, roles: Roles[]) {
    return this.http.post<AclUser>(`${this.env.usermgmtApiUrl}/unit/${unitHsaId}/users/${userHsaId}`, roles);
  }

  updateUserInUnit(unitHsaId: string, user: AclUser) {
    return this.http.put<AclUser>(`${this.env.usermgmtApiUrl}/unit/${unitHsaId}/users`, [user]);
  }

  confirmOrDeclinePendingHandoverForUnit(unitHsaId: string, handover: Handover) {
    return this.http.put<Handover>(`${this.env.baseApiUrl}/unit/${unitHsaId}/handover/${handover.id}`, handover);
  }

  switchHandlerForResident(payload: { processInstanceId: string; handlerId: string }) {
    return this.http.put(`${this.env.baseApiUrl}/processes/${payload.processInstanceId}/handler/${payload.handlerId}`, payload);
  }
}
