import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UnitService } from '../services/unit.service';
import { User } from '../models/user';
import { HttpErrorResponse } from '@angular/common/http';
import { ProcessListPageStatus } from '../pages/processes/list/process-list.types';
import { getErrorMessage } from '../utils/error.utils';

export class SwitchHandler {
  static readonly type = '[Unit] SwitchHandler';
  constructor(public payload: { processInstanceId: string; handlerId: string }) {}
}

export class LoadHandlers {
  static readonly type = '[Unit] LoadHandlers';
  constructor(public status: ProcessListPageStatus) {}
}

export class SetErrorMessage {
  static readonly type = '[Unit] SetErrorMessage';
  constructor(public message: string | null) {}
}

export interface UnitStateModel {
  handlers: User[];
  loaded: boolean;
  errorMessage: string | null;
}

@Injectable()
@State<UnitStateModel>({
  name: 'unit',
  defaults: {
    handlers: [],
    loaded: false,
    errorMessage: null,
  },
})
export class UnitState {
  constructor(private unitService: UnitService) {}

  @Selector()
  static handlers(state: UnitStateModel) {
    return state.handlers;
  }

  @Selector()
  static loaded(state: UnitStateModel) {
    return state.loaded;
  }

  @Selector()
  static errorMessage(state: UnitStateModel) {
    return state.errorMessage;
  }

  @Action(LoadHandlers)
  async loadHandlers(context: StateContext<UnitStateModel>, { status }: LoadHandlers) {
    context.patchState({ loaded: false });
    const handlers =
      status !== 'DONE'
        ? await this.unitService.getHandlersForUnit().toPromise()
        : await this.unitService.getDoneHandlersForUnit().toPromise();

    context.patchState({ handlers, loaded: true });
  }

  @Action(SwitchHandler)
  async switchHandler(context: StateContext<UnitStateModel>, { payload }: SwitchHandler) {
    context.patchState({ loaded: false });

    try {
      await this.unitService.switchHandlerForResident(payload).toPromise();
      context.patchState({ errorMessage: null });
    } catch ({ error }: HttpErrorResponse | any) {
      context.patchState({
        errorMessage: getErrorMessage(error, 'En behandling kan inte lämnas över till den behandlare som redan har den.'),
      });
    } finally {
      context.patchState({ loaded: true });
    }
  }

  @Action(SetErrorMessage)
  setErrorMessage(context: StateContext<UnitStateModel>, { message }: SetErrorMessage) {
    context.patchState({ errorMessage: message });
  }
}
