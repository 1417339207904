import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'handler-table-sorting-button',
  templateUrl: './table-sorting-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSortingButtonComponent {
  @Input() isSelectedColumn: boolean = false;
  @Input() columnName: string;
  @Input() direction: 'ASC' | 'DESC';

  @Output() toggleSortEvent = new EventEmitter<void>();

  get ariaLabel() {
    return `'Sortera listan efter ${this.columnName.toLowerCase()} i 
    ${this.direction === 'DESC' ? 'stigande' : 'fallande'} ordning'`;
  }

  toggleSort = () => this.toggleSortEvent.emit();
}
