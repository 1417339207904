import { Pipe, PipeTransform } from '@angular/core';
import { compareDate, compareString } from '../utils/comparer.utils';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform<T>(value: T[], key: keyof T, dir: 'asc' | 'desc' = 'asc'): T[] {
    if (!Array.isArray(value) || !key) {
      return value;
    }

    const sortOrder = dir === 'desc' ? -1 : 1;

    return [...value].sort((a, b) => {
      const aKey = a[key];
      const bKey = b[key];

      if (typeof aKey === 'number' && typeof bKey === 'number') {
        return sortOrder * (aKey - bKey);
      } else if (typeof aKey === 'string' && typeof bKey === 'string') {
        return sortOrder * compareString<T>(x => x[key] as unknown as string)(a, b);
      } else if (aKey instanceof Date && bKey instanceof Date) {
        return sortOrder * compareDate<T>(x => x[key] as unknown as Date)(a, b);
      }

      return 0;
    });
  }
}
