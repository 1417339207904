<div class="wrapper">
  <div class="main-header">
    <id-header
      type="1177-pro"
      srlogolabel="Till startsidan"
      [items]="isCareProviderManager ? switchCareProviderBtn : []"
      [brandtext]="appName"
      [brandtextbottom]="handler.unit ? handler.unit.name: undefined"
      [navitems]="desktopNavItems"
      [avatar]="avatar">
      <div avatar slot="dropdown">
        <id-link [block]="true" color="var(--color-main)">
          <a routerLink="/sob-handler-web/logout">Logga ut</a>
        </id-link>
      </div>
    </id-header>
  </div>
</div>

<div class="mobile-header">
  <id-header
    type="1177-pro"
    srlogolabel="Till startsidan"
    [items]="isCareProviderManager ? switchCareProviderBtn : []"
    [brandtext]="appName"
    [brandtextbottom]="handler.unit ? handler.unit.name: undefined"
    [mobilemenuitems]="mobileMenuItems"
    [avatar]="avatar">
    <div avatar slot="dropdown">
      <id-link [block]="true" color="var(--color-main)">
        <a routerLink="/sob-handler-web/logout">Logga ut</a>
      </id-link>
    </div>
  </id-header>
</div>

<ng-template #switchCareProviderModal>
  <handler-switch-care-provider-modal [handler]="handler"></handler-switch-care-provider-modal>
</ng-template>
