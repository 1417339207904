import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Announcement, AnnouncementData } from 'src/app/models/announcement';
import { compareDate } from 'src/app/utils/comparer.utils';

@Component({
  selector: 'handler-announcements',
  templateUrl: './announcements.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementsComponent {
  sortedAnnouncements: Announcement[];

  @Input() loaded: boolean;
  @Input() loading: boolean;
  @Input() showDirectionOptions = false;
  @Input() informationText: string;
  @Input() set announcements(value: Announcement[]) {
    const announcementsWithDates = value.map(announcement => ({
      ...announcement,
      startDateMoment: moment(announcement.startDate, 'YYYY-MM-DDTHH:mm:ss'),
    }));

    this.sortedAnnouncements = announcementsWithDates
      .sort(compareDate((announcement: any) => announcement.startDateMoment.toDate()))
      .reverse();
  }

  @Output() addEvent = new EventEmitter<AnnouncementData>();
  @Output() editEvent = new EventEmitter<{ announcementId: string; announcementData: AnnouncementData }>();
  @Output() removeEvent = new EventEmitter<string>();

  @ViewChild('addAnnouncementModal') addAnnouncementRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  openAddAnnouncementModal() {
    this.modalService.open(this.addAnnouncementRef, {
      size: 'md',
      centered: true,
    });
  }

  addAnnouncement(announcementData: AnnouncementData) {
    this.addEvent.emit(announcementData);
    this.closeModal();
  }

  editAnnouncement(announcementId: string, announcementData: AnnouncementData) {
    this.editEvent.emit({ announcementId, announcementData });
    this.closeModal();
  }

  removeAnnouncement(announcementId: string) {
    this.removeEvent.emit(announcementId);
    this.closeModal();
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
