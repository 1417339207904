import { ChangeDetectionStrategy, Component, Injector, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectOption } from '@sob/inera-component-library';
import { ENVIRONMENT, Handler, Unit } from '@sob/sob-resources';

@Component({
  selector: 'handler-switch-care-provider-modal',
  templateUrl: './switch-care-provider-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchCareProviderModalComponent {
  form = this.fb.group({ unitHsaid: ['', Validators.required] });
  env = this.injector.get(ENVIRONMENT);

  @Input() handler: Handler;

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private readonly injector: Injector
  ) {}

  @ViewChild('mainModal') modalRef: NgbModalRef;

  get careProviderOptions(): SelectOption[] {
    return this.createSelectOptions(this.handler.units);
  }

  createSelectOptions(units: Unit[]): SelectOption[] {
    return units.map((unit: Unit) => ({
      alternativeValue: unit.name,
      answerAlternativeNumber: unit.hsaid,
    }));
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const unitHsaId: string = this.form.value.unitHsaid;
    const redirectUrlParams = `?personHsaId=${this.handler.id}&selectedUnitHsaId=${unitHsaId}`;
    window.location.replace(`${this.env.professionLoginApiUrl}${redirectUrlParams}`);
  }

  close() {
    this.form.reset();
    this.modalService.dismissAll();
  }
}
