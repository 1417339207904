import { Pipe, PipeTransform } from '@angular/core';
import { ProviderUnit } from '../models/provider-unit';

@Pipe({
  name: 'providerUnitValidityText',
})
export class ProviderUnitValidityTextPipe implements PipeTransform {
  private getValidityText = (unit: ProviderUnit) => {
    if (unit.hsaStartDate && unit.foundInHsa) {
      return `Startdatum: ${unit.hsaStartDate}`;
    }
    if (unit.hsaEndDate && unit.foundInHsa) {
      return `Upphör: ${unit.hsaEndDate}`;
    }
    if (!unit.hsaStartDate && !unit.hsaEndDate && unit.foundInHsa) {
      return 'Tillsvidare';
    }
    return 'Borttagen från HSA';
  };

  transform(unit: ProviderUnit) {
    return this.getValidityText(unit);
  }
}
