<form>
  <id-select>
    <label for="switchCurrentView">Aktuell vy</label>
    <select id="switchCurrentView" name="options" (change)="onSelectionChange($event)">
      <option
        *ngFor="let option of menuOptions; let i = index; trackBy: trackBy"
        value="{{ option.value }}"
        [selected]="selectedView === option.value">
        {{ option.name }}
      </option>
    </select>
  </id-select>
</form>
