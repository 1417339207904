<div class="md-wpx-620">
  <sob-comments
    [displayComments]="displayComments"
    [processInstance]="processInstance"
    [userRole]="userRole"
    [entity]="entity"
    [entityId]="entityId"
    [entityType]="entityType"
    [subject]="subject"
    [residentAccountInactivated]="isResidentAccountInactivated"
    [agentAccountInactivated]="isAgentAccountInactivated"
    [useSeparator]="separatorForEntityType"
    [isHandlerMode]="true"
    [commentInModalView]="modalConversationId$ | async"
    (openCommentInModal)="openInModal($event.id)">
  </sob-comments>
</div>
