import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Conversation, ConversationService } from '@sob/sob-resources';

export class LoadConversations {
  static readonly type = '[Conversations] Load';
  constructor(public processInstanceId: number) {}
}

export class LoadConversationDetails {
  static readonly type = '[Conversations] LoadDetails';
  constructor(
    public processInstanceId: number,
    public conversationId: number
  ) {}
}

export class SetConversations {
  static readonly type = '[Conversations] Set';
  constructor(public conversations: Conversation[]) {}
}

export class CloseConversationModal {
  static readonly type = '[Conversations] Close';
}

export class OpenConversationModal {
  static readonly type = '[Conversations] Open';
  constructor(
    public processInstanceId: number,
    public conversationId: number
  ) {}
}

export class ToggleConversationModal {
  static readonly type = '[Conversations] Toggle';
}

export interface ConversationsStateModel {
  conversations: {
    [conversationId: number]: Conversation;
  };
  modalConversationId: number | null;
  modalIsMinimized: boolean;
  loaded: boolean;
}

@Injectable()
@State<ConversationsStateModel>({
  name: 'conversations',
  defaults: {
    conversations: {},
    modalConversationId: null,
    modalIsMinimized: false,
    loaded: false,
  },
})
export class ConversationsState {
  constructor(private conversationService: ConversationService) {}

  @Selector()
  static conversations(state: ConversationsStateModel) {
    return Object.values(state.conversations);
  }

  static conversation(conversationId: number) {
    return createSelector([ConversationsState], (state: ConversationsStateModel) => {
      return new Conversation().deserialize(state.conversations[conversationId]);
    });
  }

  @Selector()
  static modalConversation(state: ConversationsStateModel) {
    return state.modalConversationId && state.conversations[state.modalConversationId]
      ? new Conversation().deserialize(state.conversations[state.modalConversationId])
      : null;
  }

  @Selector()
  static modalConversationId(state: ConversationsStateModel) {
    return state.modalConversationId;
  }

  @Selector()
  static modalIsOpen(state: ConversationsStateModel) {
    return !!state.modalConversationId;
  }

  @Selector()
  static modalIsMinimized(state: ConversationsStateModel) {
    return state.modalIsMinimized;
  }

  @Selector()
  static loaded(state: ConversationsStateModel) {
    return state.loaded;
  }

  @Selector()
  static numberOfUnreadMessages(state: ConversationsStateModel) {
    return Object.values(state.conversations).reduce(
      (totalUnread, conversation) => totalUnread + (conversation.numberOfUnreadMessages ?? 0),
      0
    );
  }

  @Selector()
  static numberOfDraftMessages(state: ConversationsStateModel) {
    return Object.values(state.conversations).filter(conversation => conversation.hasDraft).length;
  }

  @Selector()
  static numberOfConversations(state: ConversationsStateModel) {
    return Object.keys(state.conversations).length;
  }

  @Action(LoadConversations)
  async loadConversations(context: StateContext<ConversationsStateModel>, { processInstanceId }: LoadConversations) {
    const conversations = (await this.conversationService.list([processInstanceId.toString()]).toPromise()) as Conversation[];
    context.patchState({
      conversations: conversations!.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
      loaded: true,
    });
  }

  @Action(LoadConversationDetails)
  async loadDetails(context: StateContext<ConversationsStateModel>, { processInstanceId, conversationId }: LoadConversationDetails) {
    const conversation = await this.conversationService.readOne([processInstanceId.toString(), conversationId.toString()]).toPromise();

    context.patchState({
      conversations: {
        ...context.getState().conversations,
        [conversationId]: conversation as Conversation,
      },
    });
  }

  @Action(SetConversations)
  setConversations(context: StateContext<ConversationsStateModel>, { conversations }: SetConversations) {
    context.patchState({
      conversations: conversations.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
    });
  }

  @Action(OpenConversationModal)
  async openConversationModal(
    context: StateContext<ConversationsStateModel>,
    { processInstanceId, conversationId }: OpenConversationModal
  ) {
    await context.dispatch(new LoadConversationDetails(processInstanceId, conversationId)).toPromise();
    context.patchState({ modalConversationId: conversationId });
  }

  @Action(CloseConversationModal)
  closeConversationModal(context: StateContext<ConversationsStateModel>) {
    context.patchState({ modalConversationId: null });
  }

  @Action(ToggleConversationModal)
  toggleConversationModal(context: StateContext<ConversationsStateModel>) {
    context.patchState({ modalIsMinimized: !context.getState().modalIsMinimized });
  }
}
