import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { ExportDownloadState, ShowLogoutModal } from '../components/export-download/export-download.state';




export const logoutGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

  const store : Store = inject(Store)

  const nonDownloadedExportCount = store.selectSnapshot(ExportDownloadState.nonDownloadedExportCount);

    if (route.queryParamMap.has('ignoreExports')) {
      redirectToLogout();
      return true;
    }

    if (nonDownloadedExportCount > 0) {
      store.dispatch(new ShowLogoutModal());
      return false;
    }

    redirectToLogout();
    return true;

}

const redirectToLogout = () => {
  sessionStorage.clear();
  window.location.href = '/sob-handler-web/logout';
}
