import { Pipe, PipeTransform } from '@angular/core';

export type WithIndex<T> = T & {
  index: number;
};

@Pipe({
  name: 'groupByNestedKey',
})
export class GroupByNestedKeyPipe implements PipeTransform {
  transform<T extends object>(value: T[], keyPath: string[]) {
    return value.reduce(
      (acc, curr, index) => {
        const key = this.getValueByPath(curr, keyPath) ?? 'Övriga';
        return {
          ...acc,
          [key]: [...(acc[key] ?? []), { ...curr, index }],
        };
      },
      {} as { [key: string]: WithIndex<T>[] }
    );
  }

  private getValueByPath(obj: any, path: string[]): any {
    return path.reduce((acc, key) => (acc && acc[key]) || null, obj);
  }
}
