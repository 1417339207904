import { Form, Module, ModuleStatus, Section, Step } from '@sob/sob-resources';
import * as moment from 'moment';

const completionDateFormat = 'YYYY-MM-DDThh:mm:ssZZ';

export const getModuleCompletionDate = (module: Module) => moment(module.completionDate).endOf('day');

export const getModuleRemainingDays = (module: Module) => {
  switch (module.handlerStatus) {
    case ModuleStatus.PENDING:
      return module.duration ?? 0;
    case ModuleStatus.INPROGRESS: {
      const now = moment();
      const completionDate = getModuleCompletionDate(module);
      return completionDate.diff(now, 'days') <= 0 ? 0 : completionDate.diff(now, 'days');
    }
    default:
      return 0;
  }
};

export const calculateCompletionDate = (module: Module, remainingDays: number) => {
  const now = moment();
  const diff = remainingDays - getModuleRemainingDays(module);
  const moduleCompletionDate = getModuleCompletionDate(module);

  return moduleCompletionDate.isBefore(now)
    ? moment(now).add(diff, 'days').format(completionDateFormat)
    : moment(moduleCompletionDate).add(diff, 'days').format(completionDateFormat);
};

export const getStepCount = (steps: Step[]): { visible: number; total: number } => ({
  visible: steps.filter(x => x.visible).length,
  total: steps.length,
});

export const getStepCountFromSections = (sections: Section[]) =>
  getStepCount(sections.reduce<Step[]>((acc, cur) => [...acc, ...cur.steps], []));

export const mapSections = (sections: Section[], forms: Form[]) => {
  const deserializedForms = forms.map(form => new Form().deserialize(form));
  return sections
    .map(section => new Section().deserialize(section))
    .map(deserializedSection => ({
      ...deserializedSection,
      steps: deserializedSection.steps.map(step => ({
        ...new Step().deserialize(step),
        forms: getRelatedForms(deserializedSection, step, deserializedForms),
      })),
    })) as Section[];
};

const getRelatedForms = (section: Section, step: Step, forms: Form[]) =>
  forms.filter(form => form?.stepRelations?.some(stepRelation => stepRelation.stepId === step.id && stepRelation.sectionId === section.id));
