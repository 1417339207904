import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StoredExportStatus } from 'src/app/pages/export/export.types';
import { trackByFn } from 'src/app/utils/track-by.utils';
import { getExportRequestStatusLabel, getExportRequestTypeLabel } from './../export-download.utils';

@Component({
  selector: 'handler-export-download-tooltip',
  templateUrl: './export-download-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportDownloadTooltipComponent {
  @Input() exportStatus: StoredExportStatus;

  getExportRequestStatusLabel = getExportRequestStatusLabel;
  getExportRequestTypeLabel = getExportRequestTypeLabel;

  get ExportRequestTypeText() {
    if (this.exportStatus.request.type === 'CATEGORY') {
      return `<strong>${getExportRequestTypeLabel('CATEGORY')}</strong>`;
    }
    return getExportRequestTypeLabel(this.exportStatus.request.type);
  }

  get startPeriod() {
    return this.getDatePeriodText(this.exportStatus.request.startFromPeriod, this.exportStatus.request.startToPeriod);
  }

  get endPeriod() {
    return this.getDatePeriodText(this.exportStatus.request.endFromPeriod, this.exportStatus.request.endToPeriod);
  }

  private getDatePeriodText(startDate?: string, endDate?: string) {
    if (startDate && endDate) {
      return `${startDate} - ${endDate}`;
    }
    if (startDate) {
      return `${startDate} och framåt`;
    }
    if (endDate) {
      return `${endDate} eller tidigare`;
    }
    return null;
  }

  stringTrackBy = trackByFn<string>(str => str);
}
