import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ERROR_HANDLER_CONFIG } from '@sob/sob-resources';

export const customErrorMessages = {
  403: `Du har inte behörighet till sidan som du försöker nå. Prova gärna att logga ut och logga in igen. Om problemet kvarstår, kontakta teknisk support.`,
  500: `Vi ber om ursäkt men det gick inte att komma åt sidan. Något har hakat upp sig och vi hoppas på snar åtgärd.`,
};

@Injectable()
export class SobErrorHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private router: Router
  ) {}

  handleError(error: any) {
    if (error.status === 0) {
      this.navigateToStatusZeroPage();
      return;
    }

    try {
      // Handle uncaught promise exception
      if (error?.promise != null) {
        const errorObject = JSON.parse(this.extractJson(error?.message ?? ''));

        if (errorObject.status === 0) {
          this.navigateToStatusZeroPage();
          return;
        }

        this.nagivateToErrorPage('HTTP', errorObject);
        return;
      }

      console.error(error);
      this.nagivateToErrorPage('HTTP', error);
    } catch {
      console.error(error);
    }
  }

  private extractJson(message: string) {
    const jsonStartIndex = message.indexOf('{');
    const jsonEndIndex = message.lastIndexOf('}');
    return message.substring(jsonStartIndex, jsonEndIndex + 1);
  }

  private navigateToStatusZeroPage() {
    this.nagivateToErrorPage('HTTP', {
      error: {
        status: '503',
        title: 'Något gick fel',
        message: 'Det gick inte att komma åt sidan. Prova att ladda om sidan eller återkom om en stund.',
      },
    });
  }

  private nagivateToErrorPage(pageType: string, error: any) {
    const errorHandlerConfig = this.injector.get(ERROR_HANDLER_CONFIG);

    this.router.navigateByUrl(`${errorHandlerConfig.errorPath}/${error?.status?.toString() ?? 500}`, {
      state: { pageType, error: { title: 'Något gick fel', ...(error?.error?.error ?? error?.error ?? error) } },
      skipLocationChange: true,
    });
  }
}
