import { Injectable } from '@angular/core';
import { Action, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Module, ModuleService, ModuleStatus } from '@sob/sob-resources';
import { ClearManageModulesState } from '../manage/modules/manage-modules.state';
import { InitMeasurementBatteries } from './../manage/measurement-batteries/measurement-batteries.state';
import { ProcessDetailsState } from './process-details.state';

const moduleStatusGroups = [ModuleStatus.INPROGRESS, ModuleStatus.PENDING, ModuleStatus.DONE] as const;

export class LoadModules {
  static readonly type = '[Modules] LoadModules';
}

export class UpdateModule {
  static readonly type = '[Modules] UpdateModule';
  constructor(
    public module: Module,
    public params?: Partial<{ terminate: boolean }>
  ) {}
}

export class ClearModulesState {
  static readonly type = '[Modules] ClearModulesState';
}

export type ModulesByStatus = { [key in (typeof moduleStatusGroups)[number]]: Module[] };
export type VisibleModuleStatus = (typeof moduleStatusGroups)[number];

export interface ModulesStateModel {
  loaded: boolean;
  modules: Module[];
}

@Injectable()
@State<ModulesStateModel>({
  name: 'modules',
  defaults: {
    modules: [],
    loaded: false,
  },
})
export class ModulesState {
  constructor(
    private store: Store,
    private moduleService: ModuleService
  ) {}

  @Selector()
  static modules(state: ModulesStateModel) {
    return state.modules.map(module => new Module().deserialize(module));
  }

  @Selector()
  static loaded(state: ModulesStateModel) {
    return state.loaded;
  }

  @SelectorOptions({ injectContainerState: false })
  @Selector([ModulesState.modules])
  static modulesByStatus(modules: Module[]) {
    return moduleStatusGroups.reduce<ModulesByStatus>(
      (acc, moduleStatus) => {
        const modulesByStatus = modules.filter(module => module.processModuleStatus === moduleStatus);
        return { ...acc, [moduleStatus]: modulesByStatus };
      },
      { [ModuleStatus.INPROGRESS]: [], [ModuleStatus.PENDING]: [], [ModuleStatus.DONE]: [] }
    );
  }

  @Action(LoadModules)
  async loadModules(context: StateContext<ModulesStateModel>) {
    context.patchState({ loaded: false });
    const processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);
    const modules = await this.moduleService.list([String(processInstance.id)]).toPromise();
    context.patchState({ modules, loaded: true });
  }

  @Action(UpdateModule)
  async updateModule(context: StateContext<ModulesStateModel>, { module, params }: UpdateModule) {
    const processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);
    const requestModule = new Module().prepareModuleForSubmit(module);
    const queryParams = params?.terminate ? '?terminate=true' : '';

    const updatedModule = await this.moduleService
      .update([String(processInstance.id), String(module.id)], requestModule, queryParams)
      .toPromise();

    context.setState(
      patch({
        modules: updateItem(
          item => item.id === module.id,
          patch({ ...updatedModule, containsReviewRequiredForm: module.containsReviewRequiredForm }) // API sends null value for containsReviewRequiredForm after update
        ),
      })
    );

    context.dispatch(new InitMeasurementBatteries());
  }

  @Action(ClearModulesState)
  clearModulesState(context: StateContext<ModulesStateModel>) {
    context.setState({ modules: [], loaded: false });
    context.dispatch(new ClearManageModulesState());
  }
}
