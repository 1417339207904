<h2 class="h2-responsive mb-4 mb-md-2 text-dark">Meddelanden</h2>
<p class="mb-4" *ngIf="processInstance.messagesEnabled">
  Här kan du skapa ny konversation, läsa och skriva meddelanden till invånaren. När det finns nya meddelanden markeras ingången och
  konversationen. Dina meddelanden sparas som utkast om du inte skickar iväg dem.
</p>
<p *ngIf="!processInstance.messagesEnabled">Här visas dina konversationer med invånaren.</p>

<sob-conversations
  [initialIds]="activeIds"
  [conversations]="(mutableConversations$ | async)!"
  [processInstance]="processInstance"
  [residentAccountInactivated]="isResidentAccountInactivated"
  [agentAccountInactivated]="isAgentAccountInactivated"
  [userRole]="userRole"
  [conversationsLoading]="(loaded$ | async) === false"
  [isHandlerMode]="true"
  [conversationInModalView]="modalConversationId$ | async"
  (openConversationInModal)="openInModal($event.id)"
  (toggleConversationAccordionEvent)="setActiveIds($event)"
  (updateTab)="updateConversations($event)">
</sob-conversations>
