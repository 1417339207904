import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {SystemSetting} from '@sob/sob-resources';
import {SystemService} from '../services/system.service';

export type SystemSettings = {
  handlerBaseUrl: string;
  showDesignerToolGui: boolean;
  enableCustomUnitsCreation: boolean;
  enableCareProvidersCreation: boolean;
  enableNationalCatalogue: boolean;
};

export class LoadSettings {
  static readonly type = '[System] LoadSettings';
}

export interface SystemStateModel {
  settings: SystemSetting[];
}

@Injectable()
@State<SystemStateModel>({
  name: 'system',
  defaults: {
    settings: [],
  },
})
export class SystemState {
  constructor(private systemService: SystemService) {}

  @Selector()
  static settings(state: SystemStateModel) {
    return {
      handlerBaseUrl: state.settings.find(setting => setting.name === 'handlerBaseUrl')?.value ?? '/',
      showDesignerToolGui: state.settings.find(setting => setting.name === 'showDesignerToolGui')?.value === 'true' ?? false,
      enableCustomUnitsCreation: state.settings.find(setting => setting.name === 'enableCustomUnitsCreation')?.value === 'true' ?? false,
      enableCareProvidersCreation:
        state.settings.find(setting => setting.name === 'enableCareProvidersCreation')?.value === 'true' ?? false,
      enableNationalCatalogue: state.settings.find(setting => setting.name === 'enableNationalCatalogue')?.value === 'true' ?? false,
    };
  }

  @Selector()
  static activityMeasurements(state: SystemStateModel) {
    return state.settings.find(setting => setting.name === 'activityMeasurements')?.value?.split(',') ?? [];
  }

  @Action(LoadSettings)
  async loadSettings(context: StateContext<SystemStateModel>) {
    const settings = await this.systemService.getSettings().toPromise();
    context.patchState({ settings });
  }
}
