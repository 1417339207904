import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, ModalContentModule, SelectModule, SubmitButtonModule } from '@sob/inera-component-library';
import { SwitchCareProviderModalComponent } from './switch-care-provider-modal.component';

@NgModule({
  declarations: [SwitchCareProviderModalComponent],
  imports: [ButtonModule, CommonModule, ModalContentModule, ReactiveFormsModule, SelectModule, SubmitButtonModule],
  exports: [SwitchCareProviderModalComponent],
})
export class SwitchCareProviderModule {}
