import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function dateTimeIntervalValidator(): ValidatorFn {
  const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

  return (form: AbstractControl) => {
    const startDate = moment(form.get('startDate')?.value + ' ' + form.get('startTime')?.value, DATE_TIME_FORMAT, true);
    const endDate = moment(form.get('endDate')?.value + ' ' + form.get('endTime')?.value, DATE_TIME_FORMAT, true);

    if (!startDate.isValid() || !endDate.isValid()) {
      return null;
    }

    if (startDate.isAfter(endDate) || startDate.isSame(endDate)) {
      return { endDateBeforeStartDate: true };
    }

    return null;
  };
}

export function dateIntervalValidator(): ValidatorFn {
  return (form: AbstractControl) => {
    const fromDate = moment(form.get('from')?.value, 'YYYY-MM-DD', true);
    const toDate = moment(form.get('to')?.value, 'YYYY-MM-DD', true);

    if (!fromDate.isValid() || !toDate.isValid()) {
      return null;
    }

    if (fromDate.isAfter(toDate)) {
      return { endDateBeforeStartDate: true };
    }

    return null;
  };
}
