import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeSv from '@angular/common/locales/sv';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { IDAlertGlobalModule, IDAlertModule, IDFooterModule, IDHeaderModule, IDIconModule } from '@inera/ids-angular';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import {
  BreadcrumbModule,
  ButtonModule,
  ContentLinkModule,
  FieldsetModule,
  ModalContentModule,
  ModalTriggerModule,
  NotificationModule,
  NotificationService,
  SelectModule,
  SubmitButtonModule,
  TabMenuModule,
  TextContentContainerModule,
  ToastService,
  TooltipButtonModule,
  TooltipContentModule,
} from '@sob/inera-component-library';
import { SessionTimeoutModule } from '@sob/inera-session';
import {
  AnnouncementService,
  ConversationService,
  CustomError,
  ENVIRONMENT,
  ERROR_HANDLER_CONFIG,
  ErrorHandlerConfig,
  FocusManagementService,
  FormService,
  GlobalErrorHandlerModule,
  HandlerService,
  ModuleService,
  ProcessSearchSessionStorageService,
  ProcessService,
  ProcessTemplateService,
  ProfessionHandlerConstantPropertyService,
  ResidentInformationService,
  ResultService,
  SectionService,
  StepVisibilityChangeService,
  StepVisibilityService,
  SubAnnouncementService,
  SystemSettingService,
  UnitHandlerService,
  UnitProcessInstanceService,
  UnitProcessTemplateService,
} from '@sob/sob-resources';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AnnouncementsModule } from './components/announcements/announcements.module';
import { ConversationModalModule } from './components/conversation-modal/conversation-modal.module';
import { ExportDownloadModule } from './components/export-download/export-download.module';
import { FooterModule } from './components/footer/footer.module';
import { HeaderModule } from './components/header/header.module';
import { PaginationModule } from './components/pagination/paginaton.module';
import { SwitchCareProviderModule } from './components/switch-care-provider/switch-care-provider.module';
import { TableSortingButtonModule } from './components/table-sorting/table-sorting-button.module';
import { HandlerPipesModule } from './pipes/pipes.module';
import { ConversationsState } from './states/conversations.state';
import { HandlerState } from './states/handler.state';
import { SystemState } from './states/system.state';
import { UnitState } from './states/unit.state';
import { SwitchCurrentViewModule } from './components/switch-current-view/switch-current-view.module';
import { SobErrorHandler } from './error-handler';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomErrorHandlerConfig } from './error.config';

registerLocaleData(localeSv, 'sv');

/** Factory method to load app constant properties when app initializes */
export function initConstantProperties(professionHandlerPropertyService: ProfessionHandlerConstantPropertyService) {
  return (): Promise<void> => {
    return professionHandlerPropertyService.loadConstantProperties(environment.constantPropertyUrl ?? '');
  };
}

/** function that returns `MarkedOptions` with renderer override */
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  return {
    renderer: renderer,
    gfm: false,
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AnnouncementsModule,
    BrowserModule,
    ButtonModule,
    BreadcrumbModule,
    AppRoutingModule,
    IDAlertModule,
    IDAlertGlobalModule,
    IDHeaderModule,
    IDFooterModule,
    IDIconModule,
    HeaderModule,
    FooterModule,
    ExportDownloadModule,
    PaginationModule,
    HandlerPipesModule,
    HttpClientModule,
    ButtonModule,
    ContentLinkModule,
    TableSortingButtonModule,
    TextContentContainerModule,
    TabMenuModule,
    TooltipButtonModule,
    TooltipContentModule,
    SelectModule,
    ButtonModule,
    SubmitButtonModule,
    SwitchCareProviderModule,
    SwitchCurrentViewModule,
    ConversationModalModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    NgxsModule.forRoot([HandlerState, UnitState, SystemState, ConversationsState], { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot({
      key: ['timeline', 'exportDownload', 'processlistfilter'],
      storage: StorageOption.SessionStorage,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    FieldsetModule,
    NotificationModule,
    FormsModule,
    ReactiveFormsModule,
    ModalContentModule,
    ModalTriggerModule,
    GlobalErrorHandlerModule.forRoot(),
    SessionTimeoutModule.forRoot({
      idle: 1500 /** 1500 = default */,
      timeout: 300 /** 300 = default */,
      logoutPath: '/sob-handler-web/logout',
      // don't trigger inactivity warning on these route(s), /login has no active session and ping call will fail.
      skippedRoutes: ['/login'],
      logoutPathOnTimeout: '/sob-handler-web/logout?reason=timeout',
    }),
    NgbModule,
  ],
  providers: [
    ConversationService,
    ProcessTemplateService,
    ProcessService,
    ModuleService,
    StepVisibilityService,
    HandlerService,
    SystemSettingService,
    ResidentInformationService,
    ResultService,
    UnitProcessTemplateService,
    UnitProcessInstanceService,
    UnitHandlerService,
    FocusManagementService,
    SectionService,
    ProcessSearchSessionStorageService,
    NotificationService,
    StepVisibilityChangeService,
    ToastService,
    FormService,
    AnnouncementService,
    SubAnnouncementService,
    { provide: LOCALE_ID, useValue: 'sv-SE' },
    { provide: ENVIRONMENT, useValue: environment },
    // which element should be focusable upon navigation (inera-content-link - 'till sidans innehåll')
    { provide: 'navigationFocusElementId', useValue: 'main-content-link' },
    { provide: ERROR_HANDLER_CONFIG, useValue: CustomErrorHandlerConfig },
    {
      provide: APP_INITIALIZER,
      useFactory: initConstantProperties,
      deps: [ProfessionHandlerConstantPropertyService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SobErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
