<div class="content-container">
  <inera-content-link anchorTag="content" linkText="Till sidans huvudinnehåll"></inera-content-link>
  <handler-header *ngIf="handler$ | async as handler" [handler]="handler" [systemSettings]="(systemSettings$ | async)!"></handler-header>

  <main id="content" tabindex="-1" class="d-flex d-print-block flex-column content">
    <div class="flex-grow-1">
      <inera-session-timeout></inera-session-timeout>
      <id-alert-global
        *ngIf="(announcementStore.announcements | async)!.length > 0"
        class="d-print-none"
        role="status"
        [headline]="(announcementStore.panelHeadline | async)!"
        showtext="Visa mer"
        hidetext="Visa mindre"
        [expanded]="(announcementStore.isExpanded | async)!"
        (onDidToggleExpansion)="updateReadStateForAnnouncements()">
        <id-icon slot="icon" title="Servicemeddelande ikon" name="information"></id-icon>
        <div
          *ngFor="let announcement of announcementStore.announcements | async; trackBy: trackByFn; let last = last"
          [ngClass]="{ 'mb-6': !last }">
          <p *ngIf="announcement.fromName" class="my-0">
            <strong>Servicemeddelande från: {{ announcement.fromName }}</strong>
          </p>
          <p *ngIf="!announcement.fromName" class="my-0"><strong>Servicemeddelande</strong></p>
          <span class="mt-2" [innerHTML]="announcement.text | transformUrlToLink"></span>
        </div>
      </id-alert-global>
      <div class="container">
        <div class="row mb-24 mb-md-30 justify-content-center">
          <div [ngClass]="(containerWidth$ | async)!">
            <handler-export-download></handler-export-download>

            <router-outlet></router-outlet>

            <!-- Notification, use only one throughout the app -->
            <inera-notification [notificationProperties]="notificationService.notificationProperties"> </inera-notification>

            <!-- Toast, use only one throughout the app, should be positioned next to triggerd element -->
            <inera-notification elementIdentifier="inera-toast" [notificationProperties]="toastService.notificationProperties">
            </inera-notification>
          </div>
          <div *ngIf="conversationModalIsOpen$ | async" class="col-4 ps-4">
            <handler-conversation-modal class="z-50"></handler-conversation-modal>
          </div>
        </div>
      </div>
    </div>
  </main>

  <handler-footer></handler-footer>
</div>
