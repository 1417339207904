import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Form } from '@sob/sob-resources';
import { ProcessDetailsState } from '../../states/process-details.state';
import { MeasurementBatteryHandlerService } from './measurement-batteries-handler.service';
import { MeasurementBattery, ScheduledMeasurementBattery } from './measurement-batteries.types';

export class InitMeasurementBatteries {
  static readonly type = '[MeasurementBatteries] Init';
}

export class LoadMeasurementBatteries {
  static readonly type = '[MeasurementBatteries] LoadMeasurementBatteries';
}

export class LoadScheduledMeasurementBatteries {
  static readonly type = '[MeasurementBatteries] LoadScheduled';
}

export class LoadFormCollections {
  static readonly type = '[MeasurementBatteries] LoadFormCollections';
}

export class CancelMeasurementBattery {
  static readonly type = '[MeasurementBatteries] Cancel';
  constructor(public battery: MeasurementBattery) {}
}

export class StartExtraMeasurement {
  static readonly type = '[MeasurementBatteries] StartExtraMeasurement';
  constructor(public battery: ScheduledMeasurementBattery) {}
}

export class ScheduleMeasurementBattery {
  static readonly type = '[MeasurementBatteries] Schedule';
  constructor(public payload: Omit<ScheduledMeasurementBattery, 'id'>) {}
}

export class EditScheduledMeasurementBattery {
  static readonly type = '[MeasurementBatteries] Edit';
  constructor(public payload: ScheduledMeasurementBattery) {}
}

export class DeleteScheduledMeasurementBattery {
  static readonly type = '[MeasurementBatteries] Delete';
  constructor(public payload: ScheduledMeasurementBattery) {}
}

export interface MeasurementBatteriesStateModel {
  loaded: boolean;
  measurementBatteries: MeasurementBattery[];
  scheduledMeasurementBatteries: ScheduledMeasurementBattery[];
  formCollections: Form[];
}

@Injectable()
@State<MeasurementBatteriesStateModel>({
  name: 'measurementBatteries',
  defaults: {
    loaded: false,
    measurementBatteries: [],
    scheduledMeasurementBatteries: [],
    formCollections: [],
  },
})
export class MeasurementBatteriesState {
  constructor(
    private store: Store,
    private measurementBatteryHandlerService: MeasurementBatteryHandlerService
  ) {}

  @Selector()
  static loaded(state: MeasurementBatteriesStateModel) {
    return state.loaded;
  }

  @Selector()
  static activeMeasurementBatteries(state: MeasurementBatteriesStateModel) {
    return state.measurementBatteries.filter(x => x.status === 'INPROGRESS');
  }

  @Selector()
  static scheduledMeasurementBatteries(state: MeasurementBatteriesStateModel) {
    return state.scheduledMeasurementBatteries;
  }

  @Selector()
  static formCollections(state: MeasurementBatteriesStateModel) {
    return state.formCollections;
  }

  @Selector()
  static measurementBatteriesHistory(state: MeasurementBatteriesStateModel) {
    const history = state.measurementBatteries.filter(x => ['CANCELLED', 'MISSED', 'DONE'].includes(x.status));
    return this.toDictionary(history);
  }

  @Action(InitMeasurementBatteries)
  async initMeasurementBatteries(context: StateContext<MeasurementBatteriesStateModel>) {
    context.patchState({ loaded: false });
    await context
      .dispatch([new LoadMeasurementBatteries(), new LoadScheduledMeasurementBatteries(), new LoadFormCollections()])
      .toPromise();
    context.patchState({ loaded: true });
  }

  @Action(LoadMeasurementBatteries)
  async loadMeasurementBatteries(context: StateContext<MeasurementBatteriesStateModel>) {
    const processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);
    const measurementBatteries = await this.measurementBatteryHandlerService
      .getMeasurementBatteries(processInstance.id.toString())
      .toPromise();

    context.patchState({ measurementBatteries });
  }

  @Action(LoadScheduledMeasurementBatteries)
  async loadScheduled(context: StateContext<MeasurementBatteriesStateModel>) {
    const processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);
    const scheduledMeasurementBatteries = await this.measurementBatteryHandlerService
      .getScheduledMeasurementBatteries(processInstance.id)
      .toPromise();

    context.patchState({ scheduledMeasurementBatteries });
  }

  @Action(LoadFormCollections)
  async loadFormCollections(context: StateContext<MeasurementBatteriesStateModel>) {
    const processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);
    const formCollections = await this.measurementBatteryHandlerService.getFormCollections(processInstance.id).toPromise();

    context.patchState({ formCollections });
  }

  @Action(CancelMeasurementBattery)
  async cancel(context: StateContext<MeasurementBatteriesStateModel>, { battery }: CancelMeasurementBattery) {
    const processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);
    await this.measurementBatteryHandlerService.cancelMeasurementBattery(battery, processInstance.id).toPromise();

    context.setState(
      patch({
        measurementBatteries: updateItem(
          x => x.id === battery.id,
          patch({
            status: 'CANCELLED',
          })
        ),
      })
    );
  }

  @Action(StartExtraMeasurement)
  async startExtraMeasurement(_: StateContext<MeasurementBatteriesStateModel>, { battery }: StartExtraMeasurement) {
    const processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);

    await this.measurementBatteryHandlerService.startExtraMeasurement(battery, processInstance.id, battery.id).toPromise();
  }

  @Action(ScheduleMeasurementBattery)
  async schedule(_: StateContext<MeasurementBatteriesStateModel>, { payload }: ScheduleMeasurementBattery) {
    const processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);

    await this.measurementBatteryHandlerService.scheduleMeasurementBattery(processInstance.id, payload).toPromise();
  }

  @Action(EditScheduledMeasurementBattery)
  async edit(context: StateContext<MeasurementBatteriesStateModel>, { payload }: EditScheduledMeasurementBattery) {
    const processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);

    await this.measurementBatteryHandlerService.editScheduled(processInstance.id, payload.id, payload).toPromise();

    context.setState(
      patch({
        scheduledMeasurementBatteries: updateItem(
          x => x.id === payload.id,
          patch({
            modules: payload.modules,
            nextDate: payload.nextDate,
          })
        ),
      })
    );
  }

  @Action(DeleteScheduledMeasurementBattery)
  async delete(context: StateContext<MeasurementBatteriesStateModel>, { payload }: DeleteScheduledMeasurementBattery) {
    const processInstance = this.store.selectSnapshot(ProcessDetailsState.processInstance);

    await this.measurementBatteryHandlerService.deleteScheduled(processInstance.id, payload.id).toPromise();

    context.setState(
      patch({
        scheduledMeasurementBatteries: removeItem(x => x.id === payload.id),
      })
    );
  }

  private static toDictionary(items: MeasurementBattery[]) {
    return items.reduce<{ [key: string]: MeasurementBattery[] }>(
      (acc, curr) => ({
        ...acc,
        [curr.name]: [...(acc[curr.name] ?? []), curr].sort((a, b) => b.created.valueOf() - a.created.valueOf()),
      }),
      {}
    );
  }
}
