<ng-container *ngIf="announcements.length > 0; else noAnnouncements">
  <inera-accordion [shouldSavePanelStateToSessionStorage]="true" sessionStorageKey="announcements-accordion">
    <inera-accordion-panel
      *ngFor="let announcement of announcements; let last = last; trackBy: trackAnnouncementByFn"
      id="active-activity-{{ announcement.id }}"
      [panelTitle]="getTitle(announcement)"
      [isRounded]="true"
      [hasTitleContentDivider]="true"
      [alignItemsCenter]="true"
      [hasDropShadow]="true"
      [hasLargeTopBorder]="true"
      [changeLayoutOnBreakpointBordered]="true"
      [accordionPanelAdditionalContent]="accordionPanelAdditionalContent"
      [rightAlignedSubtitleOnMd]="false"
      marginClass="{{ !last && 'mb-4' }}"
      chevronIconClasses="h1 pb-1"
      titlePaddingClasses="ps-lg-1 ps-xl-1 flex-grow-1"
      paddingYClass="py-lg-4"
      paddingXClass="px-md-6"
      bodyPaddingClass="p-0"
      headingTag="h3">
      <ng-template #accordionPanelAdditionalContent>
        <id-tooltip class="float-end">
          <button
            slot="trigger"
            class="btn btn-no-style me-3 ms-3"
            (click)="openEditAnnouncementModal(announcement, $event)"
            aria-label="Redigera meddelande">
            <id-icon title="Redigera meddelande" slot="trigger" name="edit" size="s"></id-icon>
          </button>
          <div slot="tooltip">Redigera meddelande</div>
        </id-tooltip>

        <id-tooltip class="float-end">
          <button
            slot="trigger"
            class="btn btn-no-style"
            (click)="openRemoveAnnouncementModal(announcement.id, $event)"
            aria-label="Ta bort meddelande">
            <id-icon title="Ta bort meddelande" slot="trigger" name="trash" color="#C71842" color2="#6A0032" size="s"></id-icon>
          </button>
          <div slot="tooltip">Ta bort meddelande</div>
        </id-tooltip>
      </ng-template>

      <ng-template ineraAccordionPanelContent>
        <div class="mx-5 mb-8">
          <h4 class="h3 mb-2">Meddelande</h4>
          <div [innerHtml]="announcement.text | transformUrlToLink"></div>
        </div>
      </ng-template>
    </inera-accordion-panel>
  </inera-accordion>
</ng-container>

<ng-template #editAnnouncementModal>
  <handler-announcements-manage-modal
    [announcement]="announcement"
    [editMode]="true"
    [showDirectionOptions]="showDirectionOptions"
    (closeEvent)="closeModal()"
    (actionEvent)="editAnnouncement($event)">
  </handler-announcements-manage-modal>
</ng-template>

<ng-template #removeAnnouncementModal>
  <handler-announcements-remove-modal (removeEvent)="removeAnnouncement()" (closeEvent)="closeModal()">
  </handler-announcements-remove-modal>
</ng-template>

<ng-template #noAnnouncements>
  <id-alert class="my-4" live="polite" type="attention">Det finns inga meddelanden</id-alert>
</ng-template>
