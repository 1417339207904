import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { HandlerState, LoadHandler } from '../states/handler.state';
import { SystemState } from '../states/system.state';
import { hasAccess } from '../utils/role.utils';
import { customErrorMessages } from '../error-handler';


// Functional page guard 
export const pageGuardCanActivate = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>{
  return await resolvRoute(route);
}

export const pageGuardCanLoad = async (route: Route) => {
  return await resolvRoute(route);
}

const resolvRoute = async (route: Route | ActivatedRouteSnapshot) => {

  const store = inject(Store);
  const router = inject(Router);

  if (!store.selectSnapshot(HandlerState.loaded)) {
    await store.dispatch(new LoadHandler()).toPromise();
  }

  const handler = store.selectSnapshot(HandlerState.handler)!;
  const systemSettings = store.selectSnapshot(SystemState.settings);

  if (hasAccess(handler, systemSettings, route.data?.page)) {
    return true;
  }

  return router.navigate(['error'], {
    state: {
      pageType: 'HTTP',
      error: {
        title: 'Du har inte behörighet',
        message: customErrorMessages[403],
      },
    },
    skipLocationChange: true,
  });
}


