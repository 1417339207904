import { ExportRequestStatus, ExportRequestType, ExportStatusProgress } from 'src/app/pages/export/export.types';

const exportStatusLabels: { [key in ExportStatusProgress]: string } = {
  NEW: 'Ny',
  QUEUED: 'Köar',
  PROCESSING: 'Under arbete',
  DONE: 'Klar för nedladdning',
  ERROR: 'Misslyckades',
  DOWNLOADED: 'Nedladdad',
};
export const getExportStatusLabel = (status: ExportStatusProgress) => exportStatusLabels[status];

export const pollingStatuses: ExportStatusProgress[] = ['NEW', 'PROCESSING', 'QUEUED'];

const exportRequestStatusLabels: { [key in ExportRequestStatus]: string } = {
  DONE: 'Avslutade moment',
  ACTIVE: 'Pågående moment',
};
export const getExportRequestStatusLabel = (status: ExportRequestStatus) => exportRequestStatusLabels[status];

const exportRequestTypeLabels: { [key in ExportRequestType]: string } = {
  RESIDENT_SINGLE: 'Enskild invånare med länkade moment',
  RESIDENT_MULTIPLE: 'Filtrerat på invånare',
  ALL: 'Alla invånare',
  CATEGORY: 'Kategorier:',
};
export const getExportRequestTypeLabel = (type: ExportRequestType) => exportRequestTypeLabels[type];
