import { Handler, ProfessionRole } from '@sob/sob-resources';
import { SystemSettings } from '../states/system.state';

export enum Page {
  ADMINISTRATION = 'ADMINISTRATION',
  ANVANDARGRUPPER = 'ANVANDARGRUPPER',
  DRIFTINFO = 'DRIFTINFO',
  FELSOKNING = 'FELSOKNING',
  HANTERA_MOMENT = 'HANTERA_MOMENT',
  HANTERA_MOMENT_MINA = 'HANTERA_MOMENT_MINA',
  HANTERA_MOMENT_ALLA = 'HANTERA_MOMENT_ALLA',
  HANTERA_MOMENT_AVSLUTADE = 'HANTERA_MOMENT_AVSLUTADE',
  HANTERA_MOMENT_EXPORT = 'HANTERA_MOMENT_EXPORT',
  INNEHALLSKATALOG = 'INNEHALLSKATALOG',
  INSTALLNINGAR = 'INSTALLNINGAR',
  MIN_PROFIL = 'MIN_PROFIL',
  NATIONELL_ADMINISTRATION = 'NATIONELL_ADMINISTRATION',
  NATIONELL_INNEHALLSKATALOG = 'NATIONELL_INNEHALLSKATALOG',
  NATIONELL_VARDGIVARE = 'NATIONELL_VARDGIVARE',
  OVERLAMNINGAR = 'OVERLAMNINGAR',
  PERSONAL = 'PERSONAL',
  STATISTIK = 'STATISTIK',
  TJANSTER = 'TJANSTER',
  UTGIVARENHETER = 'UTGIVARENHETER',
  VARDGIVARADMINISTRATION = 'VARDGIVARADMINISTRATION',
  DESIGNER = 'DESIGNER'
}

export const hasRole = (handler: Handler, roles: ProfessionRole[]) => roles.some(role => handler.roles.includes(role));

const pageAccess: Record<Page, (handler: Handler, systemSettings: SystemSettings) => boolean> = {
  ADMINISTRATION: handler => hasRole(handler, [ProfessionRole.RESIDENT_MANAGER]),
  ANVANDARGRUPPER: handler => hasRole(handler, [ProfessionRole.USER_MANAGER]) && handler.unit.publishUnit,
  DRIFTINFO: handler => hasRole(handler, [ProfessionRole.ADMIN]),
  FELSOKNING: handler => hasRole(handler, [ProfessionRole.ADMIN]),
  HANTERA_MOMENT: handler => hasRole(handler, [ProfessionRole.DATA_ADMIN, ProfessionRole.HANDLER, ProfessionRole.RESIDENT_MANAGER]),
  HANTERA_MOMENT_MINA: handler => hasRole(handler, [ProfessionRole.HANDLER]),
  HANTERA_MOMENT_ALLA: handler => hasRole(handler, [ProfessionRole.HANDLER, ProfessionRole.RESIDENT_MANAGER]),
  HANTERA_MOMENT_AVSLUTADE: handler =>
    hasRole(handler, [ProfessionRole.DATA_ADMIN, ProfessionRole.HANDLER, ProfessionRole.RESIDENT_MANAGER]),
  HANTERA_MOMENT_EXPORT: handler => hasRole(handler, [ProfessionRole.DATA_ADMIN]),
  INNEHALLSKATALOG: handler =>
    hasRole(handler, [ProfessionRole.CAREPROVIDER_MANAGER, ProfessionRole.RESIDENT_MANAGER]) && !handler.unit.publishUnit,
  INSTALLNINGAR: handler => hasRole(handler, [ProfessionRole.RESIDENT_MANAGER]),
  MIN_PROFIL: () => true,
  NATIONELL_ADMINISTRATION: handler => hasRole(handler, [ProfessionRole.NATIONAL_MANAGER]),
  NATIONELL_INNEHALLSKATALOG: (handler, systemSettings) =>
    hasRole(handler, [ProfessionRole.NATIONAL_MANAGER]) && systemSettings.enableNationalCatalogue,
  NATIONELL_VARDGIVARE: (handler, systemSettings) =>
    hasRole(handler, [ProfessionRole.NATIONAL_MANAGER]) && systemSettings.enableCareProvidersCreation,
  OVERLAMNINGAR: handler => hasRole(handler, [ProfessionRole.RESIDENT_MANAGER]),
  PERSONAL: handler =>
    hasRole(handler, [ProfessionRole.DATA_ADMIN, ProfessionRole.HANDLER, ProfessionRole.RESIDENT_MANAGER, ProfessionRole.USER_MANAGER]),
  STATISTIK: handler => hasRole(handler, [ProfessionRole.CAREPROVIDER_MANAGER, ProfessionRole.NATIONAL_MANAGER]),
  TJANSTER: handler => hasRole(handler, [ProfessionRole.ADMIN]),
  UTGIVARENHETER: (handler, systemSettings) =>
    hasRole(handler, [ProfessionRole.NATIONAL_MANAGER]) && systemSettings.enableCustomUnitsCreation,
  VARDGIVARADMINISTRATION: handler => hasRole(handler, [ProfessionRole.CAREPROVIDER_MANAGER]),
  DESIGNER: () => false
};

export const hasAccess = (handler: Handler, systemSettings: SystemSettings, page: Page) => pageAccess[page](handler, systemSettings);
