import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@sob/sob-resources';
import { ExtendedEnvironment } from 'src/environments/extended-environment.model';

export interface ProcessMultiNotifications {
  prioritized: { unit: number; handler: number };
  handovers: number;
}

@Injectable({ providedIn: 'root' })
export class ProcessMultiService {
  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT) private readonly env: ExtendedEnvironment
  ) {}

  getNotifications() {
    return this.http.get<ProcessMultiNotifications>(`${this.env.baseApiUrl}/processmulti/notifications`);
  }
}
