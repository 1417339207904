import {ExtendedEnvironment} from './extended-environment.model';

export const environment: ExtendedEnvironment = {
  production: true,
  name: 'sob-profession-handler',
  baseApiUrl: '/sob-handler-web/rest',
  baseLoginUrl: '', // property not used in profession handler
  professionLoginApiUrl: '/sob-handler-web/login', // where user gets redirected after SITHS login and selecting unit
  constantPropertyUrl: 'assets/constant-property/app-constant-property.json',
  adminApiUrl: '/sob-admin-web/rest',
  catalogueApiUrl: '/sob-catalogue-web/rest',
  usermgmtApiUrl: '/sob-usermgmt-web/rest',
  designerApiUrl: '/sob-designer-web/rest',
};
